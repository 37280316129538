import {
  type ReferentialEnum,
  referentialEnumToReferentialCodeList,
} from "@pharmupp/p3-front-commons"

export const sourcesEnum = {
  COALIA: { label: "COALIA" },
  SDAV: { label: "SDAV" },
} as const satisfies ReferentialEnum
export const sourcesReferential = referentialEnumToReferentialCodeList(sourcesEnum)

export const tagsEnum = {
  RETURN_BONUS: { label: "Bonus de remise" },
  COOP: { label: "Coopération commerciale" },
  MY_COMMITMENTS: { label: "Mon engagement" },
  RFA_ACTIVATION_PACK: { label: "Pack d'activation RFA" },
  IMPLANTATION_PACK: { label: "Pack d'implantation" },
} as const satisfies ReferentialEnum
export const tagsReferential = referentialEnumToReferentialCodeList(tagsEnum)

export const statusEnum = {
  VALIDATED: { label: "Validée" },
  NOT_VALIDATED: { label: "En cours" },
  CANCELLED: { label: "Annulée" },
} as const satisfies ReferentialEnum
export const statusReferential = referentialEnumToReferentialCodeList(statusEnum)
