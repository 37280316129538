import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { SidePanel, type SidePanelProps } from "./SidePanel"

interface SidePanelValue {
  openPanel: (conf: SidePanelConf) => void
  closePanel: () => void
}

type SidePanelConf = Omit<SidePanelProps, "open" | "onClose"> & {
  children: ReactNode
  onClose?: () => void
}

// biome-ignore lint/style/noNonNullAssertion: SidePanelContext is always provided by SidePanelProvider
const SidePanelContext = createContext<SidePanelValue>(undefined!)

export const SidePanelProvider = ({ children }: { children: ReactNode }) => {
  const [panelConfig, setPanelConfig] = useState<
    (SidePanelConf & { open: boolean }) | null
  >(null)

  const closePanel = useCallback(
    () =>
      setPanelConfig((prev) => {
        prev?.onClose?.()
        return prev ? { ...prev, open: false } : null
      }),
    [],
  )

  const openPanel = useCallback((conf: SidePanelConf) => {
    setPanelConfig((prev) => {
      // If the Drawer is already open, wait for it to close before reopening it.
      // Otherwise, the Drawer is replaced.
      if (prev?.open) {
        prev?.onClose?.()
      }
      setTimeout(() => {
        setPanelConfig({ ...conf, open: true })
      }, 225) // This time is the default animation duration.

      return { ...(prev as SidePanelConf), open: false }
    })
  }, [])

  const contextValue = useMemo(
    () => ({ openPanel, closePanel }),
    [openPanel, closePanel],
  )

  return (
    <SidePanelContext.Provider value={contextValue}>
      {children}
      {panelConfig && (
        <SidePanel {...panelConfig} onClose={closePanel}>
          {panelConfig.children}
        </SidePanel>
      )}
    </SidePanelContext.Provider>
  )
}

export const useSidePanel = () => useContext(SidePanelContext)
