import {
  ROLES,
  type RoleStrategy,
  scrollToId,
  useRoleConf,
} from "@pharmupp/p3-front-commons"

export const useDashboardMyPharmacyLink = (id?: string) =>
  useRoleConf(apiRoleConf)(id)

const apiRoleConf: RoleStrategy<(id?: string) => string> = {
  [ROLES.ADHERENT_HOLDER]: () => "/dashboard?menu%5Bitem%5D=pdm",
  [ROLES.ADHERENT_ADVISOR]: (id) => `/mes-officines/${id}`,
  [ROLES.ADMIN]: (id) => `/upp-et-utilisateurs/pharmacies/dashboard/${id}`,
}

export const scrollToPDM = () => setTimeout(() => scrollToId("pdm"), 1000)
