import {
  ROLES,
  type RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import { type ApiOrder, useOffersOrdersReferentialApi } from "../AdherentOrdersList"
import { getOrdersSelector } from "../AdherentOrdersList/tabs/commons"

const sdavCategoryEnum = "SDAV"
export const useAdherentSdavChallengeItemOrdersApi = ({
  itemId,
  year,
}: {
  itemId: number
  year: number
}) => {
  const { adherentId } = useParams()
  const ordersEndpoint = useRoleConf(apiRoleConf)

  // Order page
  const { data: orderPage, isLoading: isOrderPageLoading } = useQuery({
    queryKey: ["loyalty", "referential", "categories", sdavCategoryEnum, year],
    queryFn: () =>
      api.get<CategoryReferential>(
        `/api/loyalties/referential/category/${sdavCategoryEnum}?year=${year}`,
      ),
    select: (data) => data.orderPages?.find((page) => page.itemId === itemId),
  })

  // Order referential
  const { referential, isLoading: isReferentialLoading } =
    useOffersOrdersReferentialApi()

  // Orders list
  const {
    data: orders,
    isLoading: isOrdersLoading,
    isPlaceholderData: contentLoading,
    refetch: refresh,
  } = useQuery({
    queryKey: [
      "offers",
      "my-orders",
      "sdav-challenge",
      orderPage?.startIndex,
      orderPage?.endIndex,
      year,
      adherentId,
    ],
    queryFn: () =>
      api.get<ApiOrder[]>(
        `${ordersEndpoint}?year=${year}&startIndex=${orderPage?.startIndex}&endIndex=${orderPage?.endIndex}${adherentId ? `&adherentId=${adherentId}` : ""}`,
      ),
    enabled: !!orderPage,
    select: getOrdersSelector({ referential }),
  })

  return {
    orders,
    isLoading: isOrderPageLoading || isOrdersLoading || isReferentialLoading,
    isContentLoading: contentLoading,
    refresh,
  }
}

interface CategoryReferential {
  orderPages?: OrderPage[] // only for SDAV orders
}

interface OrderPage {
  itemId: number // to match with ChallengeHistory.descriptionItem.id
  startIndex: number
  endIndex: number
}

const apiRoleConf: RoleStrategy<string> = {
  [ROLES.ADHERENT_HOLDER]: "/api/offers/my-orders/sdav-challenge",
  [ROLES.ADMIN]: "/api/offers/orders/challenge",
  [ROLES.ADHERENT_ADVISOR]: "/api/offers/my-sector/orders/challenge",
}
