import {
  Box,
  Card,
  CardActionArea,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import { formatNumber, formatPrice } from "@pharmupp/p3-front-commons"
import {
  type OrderRow,
  ProductLabel,
  openInvoice,
  useMyLabOrdersApi,
  useMyLabOrdersCountApi,
} from "@pharmupp/p3-offers-domain"
import { add, endOfYear, startOfYear } from "date-fns"
import { CardLink, MainKpiCard, SectionContentTitle, SectionLayout } from "./commons"

const ORDERS_SIZE = 3

const date = new Date()
const year = date.getFullYear()

export const getOrderHistoryLink = (labId: string) =>
  `/offres/historique-commandes/${year}?filters%5BlabId%5D=${labId}&page=0`

export const Orders = ({ labId }: { labId: string }) => {
  const { orders, isLoading } = useMyLabOrdersApi({
    labId,
    size: ORDERS_SIZE,
  })

  const { count } = useMyLabOrdersCountApi({
    labId,
    startDate: startOfYear(date),
    endDate: add(endOfYear(date), { days: 1 }),
  })

  return (
    <SectionLayout
      rightTitle={<SectionContentTitle>3 dernières commandes</SectionContentTitle>}
    >
      <Stack gap={2}>
        <MainKpiCard
          title="COMMANDES PASSÉES"
          subTitle={
            <>
              Nombre de commandes sur {year}
              <br />
              (hors SDAV multimarques)
            </>
          }
          kpi={formatNumber(count)}
        />

        <CardLink to={getOrderHistoryLink(labId)}>
          <>
            Toutes les commandes
            <br /> COALIA et SDAV
          </>
        </CardLink>
      </Stack>

      <Stack gap={1.5} minHeight="290px">
        {isLoading ? (
          Array.from({ length: ORDERS_SIZE }).map((_, i) => (
            <Skeleton key={i} variant="rectangular" height="75px" />
          ))
        ) : (
          <>
            {orders?.length === 0 ? (
              <Typography variant="14px">Aucune commande</Typography>
            ) : (
              orders?.map((order) => (
                <OrderCard key={order.orderNumber} order={order} />
              ))
            )}
          </>
        )}
      </Stack>
    </SectionLayout>
  )
}

const OrderCard = ({ order }: { order: OrderRow }) => (
  <Card>
    <CardActionArea
      onClick={() => openInvoice(order.viewLink)}
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr 60px",
        gap: 2.5,
        px: 3,
        py: 1,
        pointerEvents: order.viewLink ? "all" : "none",
      }}
    >
      <Stack>
        {/* DATE */}
        <Box display="flex" gap={0.5}>
          <Typography
            color="primary.dark1"
            fontWeight={600}
            textTransform="uppercase"
          >
            {new Date(order.orderDate!).toLocaleDateString("fr-FR", {
              day: "2-digit",
            })}{" "}
            {new Date(order.orderDate!).toLocaleDateString("fr-FR", {
              month: "short",
            })}{" "}
          </Typography>
          <Typography color="grey.light" fontWeight={600}>
            {new Date(order.orderDate!).toLocaleDateString("fr-FR", {
              year: "2-digit",
            })}
          </Typography>
        </Box>

        {/* ORDER NUMBER */}
        <Typography
          variant="12px"
          color="grey.light"
          sx={{ textDecoration: "underline" }}
        >
          N°: {order.orderNumber}
        </Typography>
      </Stack>

      {/* PRODUCT */}
      <Box display="flex" gap={2.5}>
        <Divider orientation="vertical" flexItem />
        <ProductLabel
          title={order.offer.title}
          source={order.offerSource}
          subtitle={`ID : ${order.offer.cerpRef}`}
          tag={
            order.offerTag
              ? {
                  name: order.offerTag.label,
                  checked: order.unlocksChallenge,
                  chipVariation: order.offerTag.chipVariation,
                }
              : undefined
          }
        />
      </Box>

      {/* PRICE */}
      <Typography variant="12px" fontWeight="bold">
        {formatPrice(order.total)}
      </Typography>
    </CardActionArea>
  </Card>
)
