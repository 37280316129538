import { type KeyFigure, api, useSuspenseQuery } from "@pharmupp/p3-front-commons"
import { type Period, periodDates } from "./useLabMarketShareApi"

export const usePurchasesSummaryApi = ({
  labId,
  period,
}: { labId: string; period: Period }) => {
  const dateRange = periodDates[period]

  const endpoint = `/api/laboratories/adherent/${labId}/purchases`

  const queryKey = ["laboratories", "adherent", labId, "purchases", period]

  // GET
  const { data: purchasesSummary, isLoading } = useSuspenseQuery({
    queryKey,
    queryFn: () =>
      api.get<ApiPurchasesSummary>(
        `${endpoint}?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
      ),
  })

  return { purchasesSummary, isLoading }
}

export type ApiPurchasesSummary = {
  purchases?: KeyFigure
  channels?: {
    name: string
    purchases?: KeyFigure
  }[]
}
