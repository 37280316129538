import { Box, Link, Skeleton, Stack, Typography, styled } from "@mui/material"
import {
  Doughnut,
  formatPrice,
  noDecimalsFormatOptions,
} from "@pharmupp/p3-front-commons"
import { Suspense, useState } from "react"
import { ExternalLink } from "react-feather"
import {
  type Period,
  periodOptions,
  useLabMarketShareApi,
} from "../../api/useLabMarketShareApi"
import { useSalesSummaryApi } from "../../api/useSalesSummaryApi"
import { PeriodSelector } from "../../commons"
import {
  KpiCardsStack,
  MainKpiCard,
  SecondaryKpiCard,
  SectionContentTitle,
  SectionContentTitleSkeleton,
  SectionLayout,
} from "./commons"

export const Sales = ({ labId }: { labId: string }) => {
  return (
    <Suspense
      fallback={<Skeleton width="100%" height={316} variant="rectangular" />}
    >
      <SalesContent labId={labId} />
    </Suspense>
  )
}

const SalesContent = ({ labId }: { labId: string }) => {
  const [period, setPeriod] = useState<Period>("CURRENT_YEAR")
  const { salesSummary } = useSalesSummaryApi({ labId, period })

  return (
    <SectionLayout
      rightTitle={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* TITLE + PERIOD PICKER */}
          <SectionContentTitle>Parts de marché catégorielles</SectionContentTitle>
          <PeriodSelector
            value={period}
            onChange={setPeriod}
            options={periodOptions}
          />
        </Box>
      }
    >
      {/* SIDE CARDS */}
      <KpiCardsStack>
        <MainKpiCard
          title="VENTES"
          subTitle="Chiffre affaires"
          kpi={
            formatPrice(salesSummary?.turnover?.mainStat, noDecimalsFormatOptions) ||
            "-"
          }
          evolution={salesSummary.turnover?.evolution}
        />
        <SecondaryKpiCard
          title="Unités vendues"
          layout="evolution"
          kpi={
            salesSummary.units?.mainStat != null
              ? Math.round(salesSummary.units.mainStat)
              : "-"
          }
          evolution={salesSummary.units?.evolution}
        />
        <SecondaryKpiCard
          title="Nombre de références"
          kpi={
            salesSummary.numReferences != null
              ? Math.round(salesSummary.numReferences)
              : "-"
          }
          layout="evolution"
        />
      </KpiCardsStack>

      {/* DOUGHNUTS */}
      <Stack>
        <Suspense
          fallback={
            <DonutsContainer>
              {Array.from({ length: 3 }, (_, index) => (
                <Item key={index}>
                  <SectionContentTitleSkeleton width="60%" variant="rectangular" />
                  <Skeleton
                    variant="circular"
                    height={CHART_HEIGHT}
                    width={CHART_HEIGHT}
                  />
                </Item>
              ))}
            </DonutsContainer>
          }
        >
          <DonutsContainer>
            <Charts labId={labId} period={period} />
          </DonutsContainer>
        </Suspense>
      </Stack>
    </SectionLayout>
  )
}

const Charts = ({ labId, period }: { labId: string; period: Period }) => {
  const { labMarketShare } = useLabMarketShareApi({ labId, period })
  const isSpecialItem = labMarketShare.categories.length === 4

  return (
    <>
      {labMarketShare.categories.length ? (
        labMarketShare.categories.map(
          ({ label, roundedTotalSales, laboratorySplits }) => (
            <Item key={label} isSpecialItem={isSpecialItem}>
              <SectionContentTitle
                mb={1}
                width="80%"
                fontSize={14}
                textAlign="center"
              >
                {label}
              </SectionContentTitle>
              <Box width="inherit" height="100%">
                <Doughnut data={laboratorySplits}>
                  <Typography variant="21px" fontWeight={600} color="primary.main">
                    {formatPrice(roundedTotalSales)}
                  </Typography>
                </Doughnut>
              </Box>

              {false && (
                <Link href="TODO:" underline="hover" display="inline-block" mt={1}>
                  <Stack direction="row" gap={1} m="0 auto" color="primary.dark4">
                    <Typography variant="14px">Top marques</Typography>
                    <ExternalLink size="14px" />
                  </Stack>
                </Link>
              )}
            </Item>
          ),
        )
      ) : (
        <Box height={CHART_HEIGHT} width="100%">
          <Typography>Aucune donnée</Typography>
        </Box>
      )}
    </>
  )
}

const CHART_HEIGHT = "230px"

const DonutsContainer = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: theme.spacing(3),
  height: "100%",
}))
const Item = styled(Stack, {
  shouldForwardProp: (prop) => !(prop === "isSpecialItem"),
})<{ isSpecialItem?: boolean }>(({ theme, isSpecialItem = false }) => ({
  alignItems: "center",
  aspectRatio: 1,
  width: "clamp(225px, 22vw, 260px)",
  "@media(min-width: 1370px)": {
    width: `${isSpecialItem ? "clamp(188px, 13vw, 216px)" : "clamp(225px, 22vw, 260px)"}`,
  },
}))
