import {
  Box,
  Card,
  CardActionArea,
  Skeleton,
  Stack,
  type StackProps,
  Typography,
  styled,
} from "@mui/material"
import {
  formatNumber,
  formatPercentage,
  noDecimalsFormatOptions,
} from "@pharmupp/p3-front-commons"
import type { PropsWithChildren, ReactNode } from "react"
import { ArrowDown, ArrowRight, ArrowUp, ChevronRight } from "react-feather"
import { Link } from "react-router-dom"

export const SectionLayout = ({
  rightTitle,
  children,
}: PropsWithChildren<{ rightTitle?: ReactNode }>) => {
  return (
    <Stack gap={rightTitle ? 3 : 0}>
      <GridLayout>
        <Box /> {rightTitle}
      </GridLayout>
      <GridLayout>{children}</GridLayout>
    </Stack>
  )
}

const GridLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "3fr 7fr",
  gap: theme.spacing(10),
  alignItems: "flex-start",
}))

export const KpiCardsStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
}))

type MainKpiCardProps = {
  title: string
  subTitle: ReactNode
  kpi: ReactNode
  evolution?: number | null
  alignItems?: StackProps["alignItems"]
}

const CARD_PX = 3.5

export const MainKpiCard = (props: MainKpiCardProps) => {
  const { title, subTitle, kpi, evolution, alignItems = "inherit" } = props
  const displayEvolution = Object.prototype.hasOwnProperty.call(props, "evolution")

  return (
    <Card
      component={Stack}
      px={CARD_PX}
      py={3}
      sx={{ border: "none", bgcolor: "#f4f7ff" }}
      alignItems={alignItems}
      flex={1}
    >
      <Typography
        variant="18px"
        fontWeight={600}
        color="primary.dark1"
        textTransform="uppercase"
        mb={1}
      >
        {title}
      </Typography>
      <Typography variant="12px" fontWeight={500} color="#81adf8" mb={2}>
        {subTitle}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={1.5}
      >
        <Typography variant="29px" fontWeight={600} color="primary.dark1">
          {kpi}
        </Typography>
        {displayEvolution && (
          <Typography variant="18px">
            <Evolution value={evolution} />
          </Typography>
        )}
      </Stack>
    </Card>
  )
}

interface SecondaryKpiCardProps {
  title: string
  kpi: ReactNode
  evolution?: number | null
  layout?: "basic" | "evolution"
}
export const SecondaryKpiCard = (props: SecondaryKpiCardProps) => {
  const { title, kpi, evolution, layout = "basic" } = props
  const displayEvolution = Object.prototype.hasOwnProperty.call(props, "evolution")

  return (
    <Card
      component={Box}
      px={2}
      py={1}
      display="grid"
      gridTemplateColumns={layout === "basic" ? "1fr auto" : "4fr auto 1fr"}
      alignItems="center"
      gap={2}
      sx={{ border: "none", bgcolor: "#e2e9fc" }}
    >
      <Typography variant="12px" fontWeight={500} color="primary.dark4">
        {title}
      </Typography>
      <Typography
        variant="20px"
        fontWeight={600}
        color="common.darkBlue"
        textAlign="right"
      >
        {kpi}
      </Typography>
      {displayEvolution && (
        <Typography variant="12px">
          <Evolution value={evolution} />
        </Typography>
      )}
    </Card>
  )
}

export const Evolution = ({ value }: { value?: number | null }) => {
  const roundedValue =
    value != null ? +formatNumber(value, noDecimalsFormatOptions) : null
  const evolutionConf = getEvolutionConfig({ value: roundedValue })

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.5}
      component="span"
      fontWeight={500}
      color={evolutionConf.color}
    >
      {value != null && evolutionConf.icon}
      {value != null
        ? formatPercentage(roundedValue, {
            signDisplay: "always",
            ...noDecimalsFormatOptions,
          })
        : "N/A"}
    </Box>
  )
}

const getEvolutionConfig = ({ value }: { value?: number | null }) => {
  const status =
    value != null
      ? value > 0
        ? "positive"
        : value < 0
          ? "negative"
          : "neutral"
      : "neutral"

  return valueConfigEnum[status]
}

const valueConfigEnum = {
  positive: {
    color: "success.main",
    icon: <ArrowUp size="1.1em" />,
  },
  negative: {
    color: "error.main",
    icon: <ArrowDown size="1.1em" />,
  },
  neutral: {
    color: "grey.light",
    icon: <ArrowRight size="1.1em" />,
  },
}

export const SectionContentTitle = styled(Typography, {
  shouldForwardProp: (prop) => !(prop === "fontSize"),
})<{ fontSize?: number }>(({ theme, fontSize }) => ({
  color: theme.palette.primary.dark1,
  lineHeight: theme.typography.pxToRem(fontSize ? +fontSize + 2 : 20),
  fontSize: theme.typography.pxToRem(fontSize ? +fontSize : 18),
  fontWeight: 600,
  textTransform: "uppercase",
  marginBottom: theme.spacing(1),
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}))
export const SectionContentTitleSkeleton = styled(Skeleton)(({ theme }) => ({
  height: theme.typography.pxToRem(18),
  marginBottom: theme.spacing(2.5),
}))

export const CardLink = ({ children, to }: PropsWithChildren<{ to: string }>) => (
  <Card
    sx={{
      bgcolor: "white",
      border: "none",
      "&:hover .icon": {
        marginRight: "-5px!important",
      },
    }}
  >
    <CardActionArea
      component={Link}
      to={to}
      color="primary.light2"
      sx={{ px: CARD_PX, py: 2 }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        color="#89a1cb"
      >
        <Typography variant="16px" color="inherit">
          {children}
        </Typography>
        <Chevron size="20px" className="icon" />
      </Stack>
    </CardActionArea>
  </Card>
)

const Chevron = styled(ChevronRight)(({ theme }) => ({
  marginRight: "0px",
  transition: theme.transitions.create(["margin-right"]),
}))
