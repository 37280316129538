import { type UploadedFile, api, useQuery } from "@pharmupp/p3-front-commons"
import {
  sourcesReferential,
  statusReferential,
  tagsReferential,
} from "./adherentOrdersReferential"

export const useOffersOrdersReferentialApi = () => {
  const { data: referential, isLoading } = useQuery({
    queryKey: ["offers", "orders", "referential"],
    queryFn: () => api.get<OrdersReferential>("/api/offers/referential/orders"),
    select: (apiReferential) => ({
      sources: sourcesReferential,
      sourcesRecord: Object.fromEntries(
        sourcesReferential.map(({ label, code }) => [code, label]),
      ),
      tags: tagsReferential.sort((a, b) => a.label.localeCompare(b.label)),
      tagsRecord: Object.fromEntries(
        tagsReferential.map(({ label, code }) => [code, label]),
      ),
      laboratories: apiReferential.laboratories
        .map((lab) => ({
          label: lab.name,
          value: lab.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
      laboratoriesRecord: apiReferential.laboratories.reduce<
        Record<number, Laboratory>
      >((result, lab) => {
        result[lab.id] = lab
        return result
      }, {}),
      upps: apiReferential.upps.map((upp) => ({
        label: upp.name,
        value: upp.id,
      })),
      status: statusReferential,
    }),
  })

  return {
    referential,
    isLoading,
  }
}
export type OffersOrderReferential = ReturnType<
  typeof useOffersOrdersReferentialApi
>["referential"]

export const useAdherentOrdersReferential = () => {
  const { referential, isLoading } = useOffersOrdersReferentialApi()

  return {
    referential,
    referentialLoading: isLoading,
  }
}

export interface OrdersReferential {
  laboratories: Laboratory[]
  upps: { id: number; name: string }[]
}

export interface Laboratory {
  id: number
  name: string
  logo?: UploadedFile
}
