import { TableRow } from "@mui/material"
import { TableList } from "@pharmupp/p3-front-commons"
import { Fragment } from "react"
import type { OrderRow } from "../AdherentOrdersList"
import { columns } from "../AdherentOrdersList/tabs/commons/columns"
import { useAdherentSdavChallengeItemOrdersApi } from "./useAdherentSdavChallengeItemOrdersApi"

const cols = [
  { renderDefinition: columns.dateAndNumber, width: "15%" },
  { renderDefinition: columns.laboratory, width: "20%" },
  { renderDefinition: columns.offer, width: "55%" },
  { renderDefinition: columns.total, width: "10%" },
]

export const AdherentSdavOrdersList = ({
  itemId,
  year,
}: {
  itemId: number
  year: number
}) => {
  const { orders, isLoading, isContentLoading, refresh } =
    useAdherentSdavChallengeItemOrdersApi({
      itemId,
      year,
    })

  return (
    <TableList<OrderRow>
      initialLoading={isLoading}
      contentLoading={isContentLoading}
      emptyMessage=""
      header={null}
      entities={orders}
      entityMapper={(row) => (
        <TableRow tabIndex={-1}>
          {cols.map(({ renderDefinition, width }, columnIndex) => (
            <Fragment key={columnIndex}>
              {renderDefinition.renderRow({
                row,
                api: { refresh },
                cellProps: { sx: { width } },
              })}
            </Fragment>
          ))}
        </TableRow>
      )}
    />
  )
}
