import {
  ROLES,
  type RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"
import type { ApiLoyaltyCategory } from "../types"

export const useLoyaltyCategoryListApi = ({
  year,
  adherentId,
}: { year: number; adherentId?: string }) => {
  const endpoint = useRoleConf(apiRoleConf)(adherentId)
  const { data: challengeCategories, isLoading } = useQuery({
    queryKey: ["loyalty", "my-adherent", "detailed", year],
    queryFn: () => api.get<ApiLoyaltyCategory[]>(`${endpoint}?year=${year}`),
  })
  return {
    challengeCategories,
    loading: isLoading,
  }
}

const apiRoleConf: RoleStrategy<(id?: string) => string> = {
  [ROLES.ADHERENT_HOLDER]: () => "/api/loyalties/my-adherent/detailed",
  [ROLES.ADHERENT_TEAM]: () => "/api/loyalties/my-adherent/detailed",
  [ROLES.UPP_PRESIDENT]: () => "/api/loyalties/my-adherent/detailed",
  [ROLES.ADMIN]: (id) => `/api/loyalties/adherent/${id}/detailed`,
  [ROLES.ADHERENT_ADVISOR]: (id) =>
    `/api/loyalties/my-sector/adherent/${id}/detailed`,
}
