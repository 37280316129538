import { yupResolver } from "@hookform/resolvers/yup"
import { debounce } from "@mui/material"
import {
  FormDateField,
  FormSearchField,
  FormTextField,
} from "@pharmupp/p3-front-commons"
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react"
import { AlignLeft, CheckCircle, User } from "react-feather"
import {
  FormProvider,
  type Resolver,
  type UseFormWatch,
  useForm,
} from "react-hook-form"
import { useMeetingNoteApi } from "../../api/useMeetingNoteApi"
import { type MeetingNoteFormValues, meetingNoteSchema } from "./ MeetingNote.schema"
import { MeetingNoteCategoryRefential } from "./MeetingNoteReferential"
import { FormContainer, Section, Title } from "./commons"

export const MeetingNoteTitle = () => <Title label="SUIVI RENDEZ-VOUS" />

interface MeetingNoteForm {
  labId: string
  meetingNoteId?: number
}

const underlineColorSx = {
  "& .MuiInput-underline:before": {
    borderBottomColor: "divider",
  },
}

export const MeetingNoteForm = forwardRef(
  ({ labId, meetingNoteId }: MeetingNoteForm, ref) => {
    const [note, setNote] = useState<{
      id?: number
      newPanel: boolean
    }>({
      id: undefined,
      newPanel: true,
    })

    const [subscription, setSubscription] =
      useState<ReturnType<UseFormWatch<MeetingNoteFormValues>>>()

    const { meetingNote, sendValues, updatedId, reset } = useMeetingNoteApi({
      labId,
      meetingNoteId: note.id || meetingNoteId,
    })
    const formMethods = useForm<MeetingNoteFormValues>({
      resolver: yupResolver(
        meetingNoteSchema,
      ) as unknown as Resolver<MeetingNoteFormValues>,
    })

    const onSubmit = useCallback(
      (data: MeetingNoteFormValues) => {
        sendValues(data)
        if (note.newPanel) {
          setNote((prev) => ({
            ...prev,
            newPanel: false,
          }))
        }
      },
      [sendValues, note],
    )

    const debouncedSave = useCallback(debounce(onSubmit, 500), [])

    useImperativeHandle(
      ref,
      () => ({
        resetForm: () => {
          reset()
          setNote(() => ({
            id: undefined,
            newPanel: true,
          }))
        },
      }),
      [reset],
    )

    //If we add note to dependencies array it trigger a infinite loop of setNote
    useEffect(() => {
      setNote({
        id: note.newPanel ? meetingNoteId : (meetingNoteId ?? updatedId),
        newPanel: false,
      })
    }, [meetingNoteId, updatedId])

    useEffect(() => {
      subscription?.unsubscribe()
      formMethods.reset(meetingNote)
      setSubscription(
        formMethods.watch((data) => {
          debouncedSave(data)
        }),
      )
    }, [updatedId])

    return (
      <FormProvider {...formMethods}>
        <FormContainer>
          <FormDateField
            name="meetingDate"
            placeholder="Date du RDV"
            variant="standard"
            sx={underlineColorSx}
          />
          <FormSearchField
            placeholder="Type de RDV"
            name="category"
            variant="standard"
            options={MeetingNoteCategoryRefential}
            valueFormat="optionValue"
            sx={underlineColorSx}
          />
          <FormTextField
            name="title"
            variant="standard"
            placeholder="Titre du rendez-vous"
            sx={{
              "& input, input::placeholder": { fontWeight: "bold", fontSize: 18 },
              ...underlineColorSx,
            }}
          />
          <Section
            Icon={User}
            label="INTERVENANT(S)"
            name="participants"
            placeholder="Nom du/des participant(s) à l'échange"
          />
          <Section
            Icon={AlignLeft}
            label="RÉSUMÉ"
            name="content"
            placeholder="Notes du rendez-vous"
            multiline
          />
          <Section
            Icon={CheckCircle}
            label="PLAN D'ACTION"
            name="actions"
            placeholder="Liste des actions"
            multiline
          />
        </FormContainer>
      </FormProvider>
    )
  },
)
