import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Link as MuiLink,
  Menu as MuiMenu,
  type MenuProps as MuiMenuProps,
  styled,
  useTheme,
} from "@mui/material"
import type { Icon as FeatherIcon } from "react-feather"
import { Link } from "react-router-dom"
import type { MergeExclusive } from "type-fest"

const ICON_SIZE = "16px"

export type MenuProps = {
  items: MenuItemType[]
} & MuiMenuProps

export const Menu = ({ items, ...props }: MenuProps) => {
  const theme = useTheme()
  return (
    <MuiMenu
      {...props}
      onClick={(e) => {
        e.stopPropagation()
        props?.onClick?.(e)
      }}
    >
      {items.map((item, index) => {
        if (item.hidden) {
          return null
        }
        if ("divider" in item) {
          // biome-ignore lint/suspicious/noArrayIndexKey: items are static
          return <Divider key={index} variant="middle" />
        }
        const Icon = item.Icon
        return (
          <MenuItem
            key={item.label}
            role="menuitem"
            disabled={item.disabled}
            {...("to" in item
              ? {
                  to: item.to,
                  component: Link,
                }
              : "href" in item
                ? {
                    href: item.href,
                    component: MuiLink,
                    target: item.target ?? "_blank",
                  }
                : {
                    onClick: item.onClick,
                    component: MuiLink,
                  })}
            data-testid="menu-item"
          >
            <ItemWrapper>
              <Icon color={theme.palette.primary.main} size={ICON_SIZE} />
            </ItemWrapper>
            <Label>{item.label}</Label>
          </MenuItem>
        )
      })}
    </MuiMenu>
  )
}

export type MenuItemType = Item | DividerItem

export type Item = {
  /** React Feather icon / mandatory */
  Icon: FeatherIcon
  label: string
  disabled?: boolean
  hidden?: boolean
} & MergeExclusive<RouteItem, MergeExclusive<ClickItem, ExternalLinkItem>>

interface RouteItem {
  to: string
}
interface ClickItem {
  onClick: () => void
}
interface ExternalLinkItem {
  href: string
  target?: string
}
interface DividerItem {
  divider: true
  hidden?: boolean
}

const ItemWrapper = styled(ListItemIcon)({
  minWidth: `${ICON_SIZE}!important`,
})
const Label = styled(ListItemText)(({ theme }) => ({
  marginLeft: "6px",
  color: theme.palette.primary.main,
  "& .MuiTypography-root": {
    fontSize: "0.75rem",
  },
}))
