import { formatDateQueryParam, useResourceListApi } from "@pharmupp/p3-front-commons"
import { type ApiOrder, useOffersOrdersReferentialApi } from "./AdherentOrdersList"
import { type OrderRow, getOrdersSelector } from "./AdherentOrdersList/tabs/commons"

export const useMyLabOrdersApi = ({
  labId,
  size,
  startDate,
  endDate,
}: { labId: string; size?: number; startDate?: string; endDate?: string }) => {
  // REFERENTIAL
  const { referential } = useOffersOrdersReferentialApi()

  // GET
  const {
    list: orders,
    paginationProps: { count },
    initialLoading,
  } = useResourceListApi<ApiOrder, OrderRow>({
    queryKey: ["offers", "orders", "list", labId],
    resourceEndpoint: "/api/offers/my-orders/",
    queryParams: {
      labId,
      startDate,
      endDate,
    },
    rowsPerPage: size,
    select: getOrdersSelector({ referential }),
  })

  return { orders, count, isLoading: initialLoading }
}

export const useMyLabOrdersCountApi = ({
  labId,
  startDate,
  endDate,
}: { labId: string; startDate: Date; endDate: Date }) => {
  return useMyLabOrdersApi({
    labId,
    startDate: formatDateQueryParam(startDate),
    endDate: formatDateQueryParam(endDate),
    size: 1,
  })
}
