import {
  Box,
  Chip,
  CircularProgress,
  Link,
  Stack,
  TableCell,
  Typography,
  chipClasses,
  styled,
} from "@mui/material"
import {
  ActionButtonTrigger,
  type ActionButtonTriggerItem,
  type ColumnRenderDefinition,
  formatDateWithSlashes,
  useDialog,
  useUppsList,
} from "@pharmupp/p3-front-commons"
import { Suspense } from "react"
import { RefreshCw } from "react-feather"
import { AlertTriangle, Copy, FileText, MapPin, X } from "react-feather"
import { useSignatoryDocSigningLink } from "../../Document/useSignatoryDocSigningLink"
import { useTemplateSummaryApi } from "../../Template/useTemplateSummaryApi"
import { STATUS_COLOR } from "../../commons/components"
import {
  DialogInfoErrorMessage,
  DialogInfoErrorTitle,
} from "./SignatoryDialogInfoError"
import { useDeleteDocument } from "./hooks/useDeleteDocument"
import {
  useDownloadSignedDocument,
  useDownloadUnsignedDocument,
} from "./hooks/useDownloadDocument"
import { useResendDocument } from "./hooks/useResendDocument"
import type { ApiSignatory } from "./types"

export const columns = {
  pharmacy: {
    renderHeader: ({ width }) => <TableCell width={width}>Pharmacie</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Stack>
          <Typography fontSize="inherit" fontWeight={600}>
            {row.pharmacy.name}
          </Typography>
          <UppName uppId={row.pharmacy.uppId} />
          <Typography fontSize="inherit" color="grey.light">
            ID : {row.pharmacy.centralBusinessId} - CIP : {row.pharmacy.cip}
          </Typography>
        </Stack>
      </TableCell>
    ),
  },
  primaryHolder: {
    renderHeader: ({ width }) => <TableCell width={width}>Titulaire</TableCell>,
    renderRow: ({ row }) => (
      <TableCell>
        <Stack>
          <span>
            {`${row.pharmacy.primaryHolder?.lastName} ${row.pharmacy.primaryHolder?.firstName}`}
          </span>
          <Typography fontSize="inherit" color="primary.main">
            {row.pharmacy.primaryHolder?.email} - {row.pharmacy.primaryHolder?.phone}
          </Typography>
        </Stack>
      </TableCell>
    ),
  },
  signingLink: {
    renderHeader: ({ width }) => (
      <TableCell width={width}>Lien e-signature</TableCell>
    ),
    renderRow: ({ row, api: { templateId } }) => (
      <TableCell>
        {row.status === "UNSIGNED" ? (
          <SigningLink adherentId={row.pharmacy.id} templateId={templateId} />
        ) : (
          "-"
        )}
      </TableCell>
    ),
  },
  sendingDate: {
    renderHeader: ({ width }) => <TableCell width={width}>Date d'envoi</TableCell>,
    renderRow: ({ row }) => {
      if (row.isEsigned === false) {
        return (
          <TableCell>
            Déposé le{" "}
            {row.signingDate ? formatDateWithSlashes(row.signingDate) : "-"}
          </TableCell>
        )
      }
      return (
        <TableCell>
          {row.sendingDate ? formatDateWithSlashes(row.sendingDate) : "-"}
        </TableCell>
      )
    },
  },
  status: {
    renderHeader: ({ width }) => (
      <TableCell width={width} align="center">
        Statut
      </TableCell>
    ),
    renderRow: ({ row }) => (
      <TableCell align="right">
        <StatusDisplay
          status={row.status}
          signingDate={row.signingDate}
          isEsigned={row.isEsigned}
        />
      </TableCell>
    ),
  },
  action: {
    renderHeader: ({ width }) => <TableCell width={width} align="right" />,
    renderRow: ({ row, api: { templateId } }) => (
      <TableCell height="100%">
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Action
            status={row.status}
            pharmacy={row.pharmacy}
            templateId={templateId}
            errors={row.errors}
            isEsigned={row.isEsigned}
          />
        </Box>
      </TableCell>
    ),
  },
} satisfies Record<string, ColumnRenderDefinition<ApiSignatory, ExtraRowApi>>

export type ExtraRowApi = {
  templateId: string
}

const UppName = ({ uppId }: { uppId: number }) => {
  const { uppsNameById } = useUppsList()

  if (!uppsNameById) return null

  return (
    <Stack direction="row" alignItems="center" gap={0.5}>
      <UppIcon />
      <Typography fontSize="inherit">{uppsNameById[uppId]}</Typography>
    </Stack>
  )
}

const UppIcon = styled(MapPin)(({ theme }) => ({
  color: theme.palette.common.electricBlue,
  width: theme.typography.pxToRem(12),
  height: theme.typography.pxToRem(12),
}))

const SigningLink = ({
  templateId,
  adherentId,
}: { templateId: string; adherentId: string }) => {
  const signingLink = useSignatoryDocSigningLink(templateId, adherentId)

  if (signingLink.isLoading) {
    return <CircularProgress size={14} />
  }

  return (
    <Link
      onClick={() => signingLink.fetchToClipboard()}
      href="#"
      color="grey.light"
      sx={{ display: "flex", alignItems: "center", gap: 1 }}
    >
      <Typography variant="12px" color="common.blue">
        Copier le lien
      </Typography>
      <Copy size={14} />
    </Link>
  )
}

const StatusDisplay = ({
  status,
  signingDate,
  isEsigned,
}: Pick<ApiSignatory, "status" | "signingDate" | "isEsigned">) => {
  switch (status) {
    case "SENDING":
      return (
        <StatusChip
          variant="outlined"
          sx={{ borderColor: STATUS_COLOR.SENDING, color: STATUS_COLOR.SENDING }}
          label="Attente envoi"
        />
      )
    case "UNSIGNED":
      return (
        <StatusChip
          variant="outlined"
          sx={{ borderColor: STATUS_COLOR.UNSIGNED, color: STATUS_COLOR.UNSIGNED }}
          label="À signer"
        />
      )
    case "SIGNED":
      return (
        <Stack alignItems="flex-end" gap={0.5}>
          <StatusChip
            variant="filled"
            sx={{ bgcolor: "success.main", color: "white" }}
            label="Signé"
          />
          {isEsigned === false && <EsignedChip label="Hors process" />}
          {isEsigned === true && signingDate && (
            <Typography variant="11px" color="primary.main">
              Le {formatDateWithSlashes(signingDate)}
            </Typography>
          )}
        </Stack>
      )
    case "REFUSED":
      return (
        <StatusChip
          variant="outlined"
          sx={{ borderColor: STATUS_COLOR.REFUSED, color: STATUS_COLOR.REFUSED }}
          label="Refusé"
        />
      )
    case "ERROR":
      return (
        <StatusChip
          variant="outlined"
          sx={{ borderColor: STATUS_COLOR.ERROR, color: STATUS_COLOR.ERROR }}
          label="Erreur"
        />
      )
  }
}

const StatusChip = styled(Chip)(({ theme }) => ({
  minWidth: "80px",
  height: "auto",
  padding: theme.spacing(0.6, 1),
  ...theme.typography["12px"],
  [`& .${chipClasses.label}`]: {
    padding: 0,
  },
}))

const EsignedChip = styled(Chip)(({ theme }) => ({
  zIndex: 1,
  position: "relative",
  marginTop: theme.spacing(-1),
  padding: theme.spacing(0.3, 0),
  borderColor: theme.palette.success.main,
  minWidth: "80px",
  height: "auto",
  color: theme.palette.success.main,
  backgroundColor: "white",
  fontSize: theme.typography.pxToRem(11),
  lineHeight: theme.typography.pxToRem(11),
  [`& .${chipClasses.label}`]: {
    padding: 0,
  },
}))
EsignedChip.defaultProps = {
  variant: "outlined",
}

type ActionProps = { templateId: string } & Pick<
  ApiSignatory,
  "status" | "pharmacy" | "errors" | "isEsigned"
>

const Action = (props: ActionProps) => (
  <Suspense>
    <ActionInner {...props} />
  </Suspense>
)
const ActionInner = ({
  status,
  pharmacy,
  templateId,
  errors,
  isEsigned,
}: ActionProps) => {
  const dialog = useDialog()
  const pharmacyId = pharmacy.id
  const { summary } = useTemplateSummaryApi({ templateId })
  const { resend } = useResendDocument({ templateId, pharmacyId })
  const { deleteSignatory, deleteSignedDocument } = useDeleteDocument({
    templateId,
    pharmacyId,
  })
  const { dlUnsignedDoc, isDLUnsignedDoc } = useDownloadUnsignedDocument({
    templateId,
    pharmacyId,
  })
  const { dlSignedDoc, isDlSignedDoc } = useDownloadSignedDocument({
    templateId,
    pharmacyId,
  })

  const items: ActionButtonTriggerItem[] | null = (() => {
    const deleteItem = {
      Icon: X,
      label: "Supprimer",
      onClick: () => deleteSignatory(),
    }
    switch (status) {
      case "REFUSED":
        return null
      case "SENDING":
        return [deleteItem]
      case "SIGNED": {
        return [
          {
            Icon: FileText,
            label: isEsigned ? "Document à signer" : "Voir le document",
            onClick: () => dlSignedDoc(),
          },
          ...(!isEsigned
            ? [
                { divider: true as const },
                {
                  Icon: X,
                  label: "Supprimer",
                  onClick: () => deleteSignedDocument(),
                },
              ]
            : []),
        ]
      }

      case "ERROR":
        return [
          {
            Icon: AlertTriangle,
            label: "Détail erreur",
            hidden: status !== "ERROR",
            onClick: () =>
              dialog.info({
                title: (
                  <DialogInfoErrorTitle
                    documentName={summary?.name}
                    pharmacy={pharmacy}
                  />
                ),
                message: <DialogInfoErrorMessage errors={errors} />,
                validateText: "Fermer",
                iconSvgSrc: "/icons/circle-alert-orange.svg",
              }),
          },
          {
            Icon: RefreshCw,
            label: "Renvoyer",
            onClick: () =>
              dialog.confirm({
                title: "Renvoyer le document",
                message: "Êtes-vous sûr(e) de vouloir renvoyer le document ?",
                validateButtonText: "Renvoyer",
                validateAction: () => resend(),
              }),
          },
          { divider: true },
          deleteItem,
        ]
      case "UNSIGNED":
        return [
          {
            Icon: FileText,
            label: "Voir le document",
            onClick: () => dlUnsignedDoc(),
          },
          { divider: true },
          deleteItem,
        ]
    }
  })()

  return items ? (
    <ActionButtonTrigger
      loading={isDLUnsignedDoc || isDlSignedDoc}
      IconProps={{
        edge: "end",
        size: "small",
      }}
      items={items}
    />
  ) : null
}
