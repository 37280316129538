import {
  Box,
  Card,
  Divider,
  Stack,
  Typography,
  alpha,
  cardClasses,
  styled,
  useTheme,
} from "@mui/material"
import { useAdherentSummaryApi } from "@pharmupp/p3-adherent-domain"
import { AmountChip, PaperContainerSmallTitle } from "@pharmupp/p3-front-commons"
import { Children } from "react"
import { Check } from "react-feather"
import { useParams } from "react-router-dom"
import {
  HistoryLayout,
  LoyaltyCategoryDisplay,
  TrackingRow,
  useLoyaltyCategoryApi,
} from "../commons"
import { SdavHistoryLine } from "./SdavHistoryLine"

const category = "SDAV"

export const SDAVCategory = () => {
  const { year: yearStr = `${new Date().getFullYear()}`, adherentId } = useParams()
  const year = Number.parseInt(yearStr)
  const { loyaltyCategory } = useLoyaltyCategoryApi({
    categoryEnum: category,
    year,
    adherentId,
  })
  const { summary } = useAdherentSummaryApi({ adherentId })

  return (
    <LoyaltyCategoryDisplay
      loyaltyCategory={loyaltyCategory}
      currentYear={year}
      adherentId={adherentId}
    >
      {!!loyaltyCategory && !!summary && (
        <>
          <PaperContainerSmallTitle mb={2}>Challenges</PaperContainerSmallTitle>
          <SDAVCardLine>
            {loyaltyCategory.challenges?.map((challenge, index) => (
              <SDAVCard
                key={challenge.orderNumber}
                caption={challenge.caption!}
                quantity={challenge.numberField ?? ""}
                title={challenge.formattedLabel}
                bonus={challenge.expectedPoint}
                unlocked={challenge.unlocked}
                active={
                  !!challenge.unlocked &&
                  !loyaltyCategory.challenges?.[index + 1]?.unlocked
                }
              />
            ))}
          </SDAVCardLine>

          <TrackingRow
            category={category}
            totalEarnPoints={loyaltyCategory.totalEarnPoints}
            totalExpectedPoints={loyaltyCategory.totalExpectedPoints}
            totalUnlocked={loyaltyCategory.unlockedChallenge}
            lastYearValue={loyaltyCategory.lastYearValue}
          />

          {/* HISTORY */}
          <HistoryLayout>
            {loyaltyCategory.history?.map((history) => (
              <SdavHistoryLine
                key={history.label}
                date={history.historyDate}
                year={year}
                title={history.label}
                bonus={history.pointDiffRelative || 0}
                itemId={history.descriptionItem?.id}
                nbItemsRelative={history.nbItemsRelative}
                nbItemsRelativeGoal={history.nbItemsRelativeGoal}
                isBonus={history.descriptionItem?.bonus}
              />
            ))}
          </HistoryLayout>
        </>
      )}
    </LoyaltyCategoryDisplay>
  )
}

const SDAVCardLine = styled(Box)(({ theme: { spacing, palette }, children }) => {
  const childrenCount = Children.count(children)
  const style = {
    display: "grid",
    gridTemplateColumns: `repeat(${childrenCount}, 1fr)`,
    columnGap: spacing(2),
    // [`& .${cardClasses.root}:not(.unlocked):nth-of-type(${index})`]
    // [`& .${cardClasses.root}:not(.unlocked)`]: {
    //   backgroundColor: "red",
    // },
  }

  // Increase border color alpha for each child from left to right
  // to have a gradient effect
  const step = 1 / childrenCount
  for (let index = 1; index < childrenCount + 1; index++) {
    // @ts-expect-error cannot use computed class value as key
    style[`& .${cardClasses.root}:not(.unlocked):nth-of-type(${index})`] = {
      borderColor: alpha(palette.loyalty.dark2, step * index),
      filter: "rotate(180deg)",
    }
  }

  return style
})

const SDAVCard = ({
  caption,
  quantity,
  title,
  bonus,
  active = false,
  unlocked = false,
}: {
  caption: string
  quantity: string
  title: string
  bonus: number
  active?: boolean
  unlocked?: boolean
}) => {
  const { palette } = useTheme()

  return (
    <Card
      component={Stack}
      justifyContent="center"
      alignItems="center"
      spacing={3}
      py={5}
      px={3}
      className={unlocked ? "unlocked" : undefined}
      sx={{
        borderWidth: "1px",
        borderStyle: "solid",
        ...(active && {
          backgroundColor: palette.loyalty.main,
          borderColor: `${palette.loyalty.main} !important`,
        }),
        ...(unlocked && {
          borderColor: `${palette.loyalty.light3} !important`,
        }),
      }}
    >
      <Stack alignItems="center" width="60%" spacing={1}>
        <Typography
          fontSize={12}
          color={active ? palette.loyalty.light3 : palette.grey.light}
        >
          {caption}
        </Typography>
        <Divider
          flexItem
          sx={{
            borderColor: active ? palette.loyalty.light3 : palette.loyalty.light4,
          }}
        />
      </Stack>
      <SDAVCardQuantity color={active ? "white" : palette.loyalty.main}>
        {quantity}
      </SDAVCardQuantity>
      <SDAVCardTitle color={active ? "white" : palette.common.darkBlue}>
        {title}
      </SDAVCardTitle>
      <AmountChip
        variant="outlined"
        unit=" pts"
        color={active ? "white" : palette.loyalty.main}
        borderColor={active ? palette.loyalty.light2 : palette.loyalty.main}
        PrefixIcon={active ? Check : undefined}
      >
        {bonus}
      </AmountChip>
    </Card>
  )
}
const SDAVCardQuantity = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(21),
  fontWeight: 600,
}))
const SDAVCardTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  textAlign: "center",
}))
