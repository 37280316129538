import { Yup, type YupTypes } from "@pharmupp/p3-front-commons"

export const meetingNoteSchema = Yup.object({
  meetingDate: Yup.string().nullable(),
  title: Yup.string().nullable(),
  category: Yup.string().nullable(),
  content: Yup.string().nullable(),
  actions: Yup.string().nullable(),
  participants: Yup.string().nullable(),
})

export type MeetingNoteFormValues = YupTypes.InferType<typeof meetingNoteSchema>
