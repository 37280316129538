import styled from "@emotion/styled"
import { Stack, Typography, type TypographyProps, useTheme } from "@mui/material"
import {
  FieldsColumn,
  FormTextField,
  type FormTextFieldProps,
} from "@pharmupp/p3-front-commons"
import type { PropsWithChildren } from "react"
import type { Icon as FeatherIcon } from "react-feather"

export const FormContainer = styled(FieldsColumn)(({ theme: { palette } }) => ({
  gap: 1,
  padding: 3,
  "& .MuiInput-root": { color: palette.common.darkBlue },
}))

export const Title = ({
  label,
  Icon,
  ...props
}: { label: string; Icon?: FeatherIcon } & TypographyProps) => {
  const {
    palette: {
      primary: { main },
    },
  } = useTheme()
  const size = 14
  return (
    <Stack gap={1.8} direction="row" alignItems="center">
      {Icon && <Icon size={size + 2} color={main} />}
      <Typography fontWeight={600} variant={`${size}px`} color={main} {...props}>
        {label}
      </Typography>
    </Stack>
  )
}

export const Section = ({
  label,
  Icon,
  children,
  ...props
}: PropsWithChildren<
  { label: string; Icon?: FeatherIcon } & FormTextFieldProps
>) => {
  return (
    <FieldsColumn pt={2}>
      <Title Icon={Icon} label={label} />
      {children}
      <FormTextField
        variant="standard"
        sx={{
          "&.MuiTextField-root": { marginTop: 1 },
          "& .MuiInput-root::before,.MuiInput-root::after": {
            content: "none",
          },
        }}
        {...props}
      />
    </FieldsColumn>
  )
}
