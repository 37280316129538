import { NumberFormat, pluralize } from "@pharmupp/p3-front-commons"
import type { ApiLoyaltyCategoryEnum } from "../types"

export const getStatusProp = ({
  quantity,
  category,
}: {
  quantity: number
  category: ApiLoyaltyCategoryEnum
}) => {
  switch (category) {
    case "CATEGORICAL":
      return {
        boldPart: (
          <NumberFormat
            value={quantity}
            suffix={` ${pluralize(quantity, "catégorie", "catégories")}`}
          />
        ),
        regularPart: pluralize(quantity, "validée", "validées"),
      }
    case "SDAV":
      return {
        boldPart: (
          <NumberFormat
            value={quantity}
            suffix={` ${pluralize(quantity, "palier", "paliers")}`}
          />
        ),
        regularPart: pluralize(quantity, "atteint", "atteints"),
      }
    case "QUALITATIVE":
      return {
        boldPart: (
          <NumberFormat
            value={quantity}
            suffix={` ${pluralize(quantity, "challenge", "challenges")}`}
          />
        ),
        regularPart: pluralize(quantity, "réalisé", "réalisés"),
      }
  }
}
