import {
  ROLES,
  type RoleStrategy,
  api,
  useQuery,
  useRoleConf,
} from "@pharmupp/p3-front-commons"

export const useLoyaltySummaryApi = ({
  year,
  adherentId,
}: { year: number; adherentId?: string }) => {
  const endpoint = useRoleConf(apiRoleConf)(adherentId)
  const { data: summary, isLoading } = useQuery({
    queryKey: ["loyalty", adherentId ?? "my-adherent", "summary", year],
    queryFn: () => api.get<ApiLoyaltySummary>(`${endpoint}?year=${year}`),
  })

  return { summary, isLoading }
}

interface ApiLoyaltySummary {
  earnedPoint: number
  expectedPoint: number
  lastYearEarnedPoints: number
  ratio: number
}

const apiRoleConf: RoleStrategy<(id?: string) => string> = {
  [ROLES.ADHERENT_HOLDER]: () => "/api/loyalties/my-adherent/summary",
  [ROLES.ADHERENT_TEAM]: () => "/api/loyalties/my-adherent/summary",
  [ROLES.UPP_PRESIDENT]: () => "/api/loyalties/my-adherent/summary",
  [ROLES.ADMIN]: (id) => `/api/loyalties/adherent/${id}/summary`,
  [ROLES.ADHERENT_ADVISOR]: (id) =>
    `/api/loyalties/my-sector/adherent/${id}/summary`,
}
