import { Box, Skeleton, Stack, styled } from "@mui/material"
import {
  SalesCard,
  formatPrice,
  noDecimalsFormatOptions,
} from "@pharmupp/p3-front-commons"
import { Suspense, useState } from "react"
import { type Period, periodOptions } from "../../api/useLabMarketShareApi"
import { usePurchasesSummaryApi } from "../../api/usePurchasesSummaryApi"
import { PeriodSelector } from "../../commons"
import {
  KpiCardsStack,
  MainKpiCard,
  SectionContentTitle,
  SectionLayout,
} from "./commons"

export const Purchases = ({ labId }: { labId: string }) => (
  <Suspense fallback={<Skeleton height={249} width="100%" variant="rectangular" />}>
    <PurchasesContent labId={labId} />
  </Suspense>
)

const PurchasesContent = ({ labId }: { labId: string }) => {
  const [period, setPeriod] = useState<Period>("CURRENT_YEAR")
  const { purchasesSummary } = usePurchasesSummaryApi({
    labId,
    period,
  })

  return (
    <SectionLayout
      rightTitle={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* TITLE + PERIOD PICKER */}
          <SectionContentTitle>Suivi par canal (HT)</SectionContentTitle>
          <PeriodSelector
            value={period}
            onChange={setPeriod}
            options={periodOptions.filter(
              ({ value }) => value === "CURRENT_YEAR" || value === "LAST_YEAR",
            )}
          />
        </Box>
      }
    >
      {/* SIDE CARDS */}
      <KpiCardsStack>
        <MainKpiCard
          title="ACHATS"
          subTitle="Montant total"
          kpi={
            formatPrice(
              purchasesSummary.purchases?.mainStat,
              noDecimalsFormatOptions,
            ) || "-"
          }
          evolution={purchasesSummary.purchases?.evolution}
        />
      </KpiCardsStack>

      {/* CARDS */}
      <Stack>
        <GridLayout>
          {purchasesSummary.channels?.map(({ name, purchases }) => (
            <SalesCard
              key={name}
              title={name === "sagitta" ? "ma boutique" : name}
              kpi={purchases?.mainStat}
              evol={purchases?.evolution}
            />
          ))}
        </GridLayout>
      </Stack>
    </SectionLayout>
  )
}

const GridLayout = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(3),
  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  justifyContent: "center",
  justifyItems: "center",
  rowGap: theme.spacing(2),
  width: "100%",
}))
