import {
  Stack,
  type TableCellProps,
  TableRow,
  Tooltip,
  alertClasses,
} from "@mui/material"
import {
  Fragment,
  type ReactElement,
  type ReactNode,
  useMemo,
  useState,
} from "react"
import type { MergeExclusive } from "type-fest"
import { TableFiltersSection } from "./TableFiltersSection"
import { TableList } from "./TableList"
import {
  BottomPagination,
  ExportButton,
  type ExportButtonProps,
  SearchField,
  TableToolbar,
} from "./TableToolbar"
import { useResourceListApi } from "./useResourceListApi"
import type { Config, SortProps } from "./useResourceListApi/types"

export type TableProps<QueryResource, Resource, ExtraRowApi = {}> = {
  // API
  queryConfig: Config<QueryResource, Resource>
  // Table
  emptyMessage: string
  extraRowApi?: ExtraRowApi
  // Filters
  filterDefaultValues?: Record<string, unknown>
  FilterSectionElement?: ReactNode
  HiddenFilterSectionElement?: ReactNode
  // Toolbar
  pageSizes?: { label: string; value: number }[]
  exportButtonProps?: ExportButtonProps
  searchDefaultOpen?: boolean
  searchPlaceholder?: string
  extraToolbarElements?: ReactNode

  // Alert banners
  renderAlerts?: (args: { itemsLength: number }) => ReactNode
} & MergeExclusive<
  {
    /** @deprecated please use columns instead */
    renderHeader: (config: { sortProps: SortProps }) => ReactElement
    /** @deprecated please use columns instead */
    renderRow: (
      config: {
        row: Resource
        api: RowApi & ExtraRowApi
      },
      index: number,
    ) => ReactElement
  },
  {
    columns: ColumnDefinition<Resource, ExtraRowApi>[]
    onRowClick?: (row: Resource) => void
    rowClickCondition?: (row: Resource) => boolean
    renderRowTooltip?: (row: Resource) => ReactNode
  }
>

/**
 * Full featured table component for paginated api resources:
 * Handles API call, pagination, search, filter
 * High level component regrouping exported hooks/components
 */
export function Table<
  QueryResource = unknown,
  Resource = QueryResource,
  ExtraRowApi = {},
>({
  // API
  queryConfig,
  // Table
  emptyMessage,
  renderHeader,
  renderRow,
  columns,
  onRowClick,
  rowClickCondition,
  renderRowTooltip,
  extraRowApi,
  // Filters
  filterDefaultValues,
  FilterSectionElement,
  HiddenFilterSectionElement,
  // Toolbar
  exportButtonProps,
  searchDefaultOpen,
  searchPlaceholder,
  extraToolbarElements,
  pageSizes,
  renderAlerts,
}: TableProps<QueryResource, Resource, ExtraRowApi>) {
  // Size
  const [pageSize, setPageSize] = useState(queryConfig.rowsPerPage || 12)

  // API
  const {
    list,
    initialLoading,
    contentLoading,
    paginationProps,
    sortProps,
    isFiltered,
    ...api
  } = useResourceListApi({
    ...queryConfig,
    rowsPerPage: pageSize,
    routeStateEnabled: true,
  })

  const header = useMemo(
    () =>
      columns ? (
        <TableRow>
          {columns.map(({ renderDefinition, width }, index) => (
            <Fragment key={index}>
              {renderDefinition.renderHeader({ sortProps, width })}
            </Fragment>
          ))}
        </TableRow>
      ) : (
        renderHeader({ sortProps })
      ),
    [columns, renderHeader, sortProps],
  )
  const rowApi = Object.assign({ refresh: api.refresh }, extraRowApi)

  return (
    <Stack height="100%" spacing={0}>
      {/* ALERTS */}
      {!!renderAlerts && (
        <Stack
          spacing={0}
          sx={{
            [`.${alertClasses.root}`]: {
              borderRadius: "0px",
              px: 4,
            },
          }}
        >
          {renderAlerts({ itemsLength: list?.length || 0 })}
        </Stack>
      )}

      {/* TOOLBAR */}
      <TableToolbar
        paginationProps={paginationProps}
        {...(pageSizes !== undefined
          ? { sizes: pageSizes, pageSize, setPageSize }
          : { sizes: undefined })}
      >
        {!!exportButtonProps && <ExportButton {...exportButtonProps} />}
        <SearchField
          onSubmit={api.search}
          value={api.currentSearch}
          defaultOpen={searchDefaultOpen}
          placeholder={searchPlaceholder}
        />
        {extraToolbarElements}
      </TableToolbar>

      {/* FILTERS SECTION */}
      {!!FilterSectionElement && (
        <TableFiltersSection
          onChange={api.filter}
          values={api.currentFilters}
          defaultValues={filterDefaultValues}
          hiddenFilters={HiddenFilterSectionElement}
        >
          {FilterSectionElement}
        </TableFiltersSection>
      )}

      {/* LIST */}
      <TableList
        initialLoading={initialLoading}
        contentLoading={contentLoading}
        emptyMessage={isFiltered ? "Aucun résultat" : emptyMessage}
        header={header}
        entities={list}
        entityMapper={(row, index) =>
          columns ? (
            <Tooltip
              title={renderRowTooltip?.(row)}
              hidden={!renderRowTooltip}
              key={index}
            >
              <TableRow
                tabIndex={-1}
                onClick={
                  rowClickCondition
                    ? rowClickCondition(row)
                      ? () => onRowClick?.(row)
                      : undefined
                    : () => onRowClick?.(row)
                }
                hover={
                  !!onRowClick && (rowClickCondition ? rowClickCondition(row) : true)
                }
              >
                {columns.map(({ renderDefinition }, columnIndex) => (
                  <Fragment key={columnIndex}>
                    {renderDefinition.renderRow({ row, api: rowApi })}
                  </Fragment>
                ))}
              </TableRow>
            </Tooltip>
          ) : (
            renderRow({ row, api: rowApi }, index)
          )
        }
      />

      <BottomPagination
        paginationProps={paginationProps}
        listLength={list?.length || 0}
      />
    </Stack>
  )
}

interface RowApi {
  refresh: () => void
}

export interface ColumnRenderDefinition<RowValue, ExtraRowApi = {}> {
  renderHeader: (params: { sortProps: SortProps; width: string }) => ReactNode
  renderRow: (params: {
    api: RowApi & ExtraRowApi
    row: RowValue
    cellProps?: TableCellProps
  }) => ReactNode
}

export interface ColumnDefinition<RowValue, ExtraRowApi = {}> {
  renderDefinition: ColumnRenderDefinition<RowValue, ExtraRowApi>
  /** Column width in percentage eg: "12%" */
  width: string
}
