import { Box, Stack, styled } from "@mui/material"
import { PaperContainerSmallTitle } from "@pharmupp/p3-front-commons"
import { useParams } from "react-router-dom"
import {
  CardLabel,
  type Challenge,
  LoyaltyCategoryDisplay,
  RatingCardGrid,
  RatingCardLabel,
  RatingCardLayout,
  RatingCardQuantity,
  RatingCardValue,
  TrackingRow,
  useLoyaltyCategoryApi,
} from "./commons"
import { SubCategoryBonus } from "./commons/SubCategoryBonus"

const category = "QUALITATIVE"

export const QualitativeCategory = () => {
  const { year: yearStr = `${new Date().getFullYear()}`, adherentId } = useParams()
  const year = Number.parseInt(yearStr)
  const { loyaltyCategory } = useLoyaltyCategoryApi({
    categoryEnum: category,
    year,
    adherentId,
  })
  return (
    <LoyaltyCategoryDisplay
      loyaltyCategory={loyaltyCategory}
      currentYear={year}
      adherentId={adherentId}
    >
      {!!loyaltyCategory && (
        <>
          <Stack gap={7}>
            {loyaltyCategory.subCategories?.map((subCategory) => (
              <Box key={subCategory.name}>
                <PaperContainerSmallTitle mb={2}>
                  {subCategory.name}
                </PaperContainerSmallTitle>
                {subCategory.hasBonus ? (
                  <SubCategoryBonus
                    key={subCategory.name}
                    subCategory={subCategory}
                    renderNonBonusCard={(challenge) => (
                      <QualitativeCard challenge={challenge} />
                    )}
                  />
                ) : (
                  <RatingCardGrid>
                    {(subCategory.challenges || []).map((challenge) => (
                      <QualitativeCard
                        key={challenge.formattedLabel}
                        challenge={challenge}
                      />
                    ))}
                  </RatingCardGrid>
                )}
              </Box>
            ))}
          </Stack>

          {/* TRACKING */}
          <TrackingRow
            category={category}
            totalEarnPoints={loyaltyCategory.totalEarnPoints}
            totalExpectedPoints={loyaltyCategory.totalExpectedPoints}
            totalUnlocked={loyaltyCategory.unlockedChallenge}
            lastYearValue={loyaltyCategory.lastYearValue}
          />
        </>
      )}
    </LoyaltyCategoryDisplay>
  )
}

const QualitativeCard = ({
  challenge: {
    numberField: quantity,
    caption,
    formattedLabel: label,
    expectedPoint,
    unlocked: active,
    unlocked,
    totalPointsUnlocked,
    tooltip,
    unitSuffix,
  },
}: { challenge: Challenge }) => {
  const value = unlocked && totalPointsUnlocked ? totalPointsUnlocked : expectedPoint
  const unit = ` pts${unitSuffix || ""}`
  return (
    <RatingCardLayout active={active}>
      <RatingCardLabel>
        {/* QUANTITY */}
        {!!quantity && (
          <RatingCardQuantity active={active}>{quantity}</RatingCardQuantity>
        )}

        {/* LABEL */}
        <CardLabelContainer>
          {!!caption && <small>{caption}</small>}
          <CardLabel label={label} tooltip={tooltip} />
        </CardLabelContainer>
      </RatingCardLabel>
      <RatingCardValue active={active} value={value} unit={unit} />
    </RatingCardLayout>
  )
}

const CardLabelContainer = styled(Stack)(({ theme: { palette, typography } }) => ({
  color: palette.common.darkBlue,
  "& small": {
    fontSize: typography.pxToRem(11),
  },
  "& p": {
    margin: 0,
    lineHeight: typography.pxToRem(17),
  },
}))
