import { Box, Typography } from "@mui/material"
import { useMemo } from "react"
import { formatPercentage, noDecimalsFormatOptions } from "../numberDisplays"

export interface EvolutionStatChipProps {
  evol?: number | null
  neutral?: boolean
  config?: {
    formatOptions?: Intl.NumberFormatOptions
    noValue?: string
  }
}

export const EvolutionStatChip = ({
  evol,
  neutral = false,
  config,
}: EvolutionStatChipProps) => {
  const mergedConfig = useConfig({ config })
  const value = config?.formatOptions ? evol : evol ? Math.round(evol) : evol

  const colorStatus: keyof typeof COLOR =
    neutral || !value ? "neutral" : value > 0 ? "positive" : "negative"

  return (
    <Pill
      color={mergedConfig.color[colorStatus]}
      value={value}
      extraOptions={mergedConfig.formatOptions}
      noValue={mergedConfig.noValue}
    />
  )
}

const COLOR = {
  positive: "success.main",
  negative: "error.main",
  neutral: "grey.light",
} as const

const useConfig = ({ config }: Pick<EvolutionStatChipProps, "config">) =>
  useMemo(
    () => ({
      color: COLOR,
      formatOptions: {
        signDisplay: "exceptZero" as Intl.NumberFormatOptions["signDisplay"],
        ...noDecimalsFormatOptions,
        ...(config?.formatOptions || {}),
      },
      noValue: config?.noValue || "N/A",
    }),
    [config],
  )

const Pill = ({
  color,
  value,
  extraOptions,
  noValue,
}: {
  color: string
  value?: number | null
  extraOptions: Intl.NumberFormatOptions
  noValue: string
}) => {
  return (
    <Box
      border={1}
      borderColor={color}
      px={1}
      borderRadius={2000}
      width="fit-content"
    >
      <Typography color={color}>
        {value != null ? formatPercentage(value, extraOptions) : noValue}
      </Typography>
    </Box>
  )
}
