import { Error as ErrorIcon } from "@mui/icons-material"
import {
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  PaperContainer,
  type PaperContainerProps,
  formatNumber,
  formatPrice,
} from "@pharmupp/p3-front-commons"
import { Info } from "react-feather"
import { useLoyaltyApi } from "../../../api/useLoyaltyApi"
import { PeriodSelector, type PeriodSelectorOption } from "../../../commons"
import { Flag } from "./icons/Flag"
import { Money } from "./icons/Money"

type LoyaltyBlockProps = { labId: string } & PaperContainerProps

export const LoyaltyBlock = ({ labId, ...props }: LoyaltyBlockProps) => {
  const { breakpoints } = useTheme()
  return (
    <PaperContainer
      {...props}
      position="relative"
      sx={{
        [breakpoints.down("md")]: {
          overflowY: "scroll",
          height: 355,
        },
      }}
    >
      <LoyaltyBlockInner labId={labId} />
    </PaperContainer>
  )
}

const LoyaltyBlockInner = ({ labId }: { labId: string }) => {
  const { palette, spacing, breakpoints } = useTheme()
  const isSmallScreen = useMediaQuery(breakpoints.down("md"))
  // API
  const { loyalty, year, setYear } = useLoyaltyApi({ labId })

  return (
    <Box
      display="grid"
      gap={3}
      height="100%"
      pt={1}
      sx={{
        [breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
        },
        gridTemplateColumns: "1fr auto 1fr",
      }}
    >
      {/* SELECT */}
      <PeriodSelector<number>
        value={year}
        onChange={setYear}
        options={yearOptions}
        sx={{
          position: "absolute",
          top: spacing(1.5),
          right: spacing(1.25),
        }}
      />

      <Stack gap={1}>
        <Circle bgcolor={palette.loyalty.main}>
          <Flag />
        </Circle>

        <Stack direction="row" alignItems="center" gap={1} mb={2}>
          <Title>CHALLENGES</Title>
          <Tooltip title="Points des challenges pour lesquels des commandes ont été passées pour le laboratoire courant">
            <Info color={palette.primary.light3} size="23px" />
          </Tooltip>
        </Stack>

        <KpisWrapper>
          <KpiWrapper>
            <KpiLabel>Challenge Catégoriels</KpiLabel>
            {loyalty.categoricalChallenge ? (
              <KPI>
                {loyalty.categoricalChallenge.points != null
                  ? `${formatNumber(loyalty.categoricalChallenge.points)} pts`
                  : "-"}
              </KPI>
            ) : (
              <ManagementRule rule="ineligible" />
            )}
          </KpiWrapper>

          <KpiWrapper>
            <KpiLabel>Challenge {loyalty.ordersChallenge.categoryName}</KpiLabel>
            {loyalty.ordersChallenge.points != null ? (
              <>
                <KPI>{`${formatNumber(loyalty.ordersChallenge.points)} pts`}</KPI>
                <Stack direction="row" alignItems="center" gap="0.2em">
                  <KpiOptionalLabel
                    color={
                      loyalty.ordersChallenge.countOrders > 0
                        ? "primary.main"
                        : "common.orangeDark3"
                    }
                  >
                    dont {loyalty.ordersChallenge.countOrders}{" "}
                    {loyalty.ordersChallenge.countOrders > 1
                      ? "commandes"
                      : "commande"}
                  </KpiOptionalLabel>
                  {loyalty.ordersChallenge.countOrders === 0 && (
                    <ErrorIcon
                      sx={{ width: "18px", color: palette.common.orangeDark3 }}
                    />
                  )}
                </Stack>
              </>
            ) : (
              <ManagementRule rule="ineligible" />
            )}
          </KpiWrapper>
        </KpisWrapper>
      </Stack>

      <Divider orientation={isSmallScreen ? "horizontal" : "vertical"} flexItem />

      <Stack gap={1} sx={{ [breakpoints.down("md")]: { pb: 4 } }}>
        <Circle bgcolor="#f67848">
          <Money />
        </Circle>

        <Title mb={2}>COMPENSATION</Title>

        <KpisWrapper>
          <KpiWrapper>
            <KpiLabel>Pharmedisound</KpiLabel>
            {loyalty.pharmediSoundCompensation != null ? (
              <KPI>
                {loyalty.pharmediSoundCompensation
                  ? formatPrice(loyalty.pharmediSoundCompensation)
                  : "-"}
              </KPI>
            ) : (
              <ManagementRule rule="soon" />
            )}
          </KpiWrapper>

          <KpiWrapper>
            <KpiLabel>Opeaz</KpiLabel>
            <KPI>
              {loyalty.opeazCompensation != null
                ? formatPrice(loyalty.opeazCompensation)
                : "-"}
            </KPI>
          </KpiWrapper>
        </KpisWrapper>
      </Stack>
    </Box>
  )
}

const currentYear = new Date().getFullYear()
const yearOptions: PeriodSelectorOption<number>[] = [
  { value: currentYear, label: currentYear },
  { value: currentYear - 1, label: currentYear - 1 },
]

const Circle = styled(Box)(({ theme: { palette, spacing } }) => ({
  marginBottom: spacing(1.5),
  width: "47px",
  height: "47px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}))

const Title = styled(Typography)(({ theme: { palette, spacing, typography } }) => ({
  fontSize: typography.pxToRem(19),
  fontWeight: 500,
  lineHeight: typography.pxToRem(21),
  color: palette.common.darkBlue,
  textTransform: "uppercase",
}))

const KpisWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(2),
}))

const KpiWrapper = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(0.5),
}))

const KPI = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(22),
  lineHeight: typography.pxToRem(24),
  fontWeight: 600,
  color: palette.primary.dark1,
}))

const ManagementRule = ({ rule }: { rule: "soon" | "ineligible" }) => (
  <Typography variant="18px" fontWeight={600} color="primary.light1">
    {rule === "soon" ? "Bientôt disponible" : "Non éligible à date"}
  </Typography>
)

const KpiOptionalLabel = styled(Typography)(
  ({ theme: { palette, typography } }) => ({
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(14),
  }),
)

const KpiLabel = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(12),
  lineHeight: typography.pxToRem(14),
  color: palette.grey.light,
}))
