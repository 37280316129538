import {
  Box,
  Card,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { ButtonLink, MarkdownDisplay } from "@pharmupp/p3-front-commons"
import { ChevronRight, Info } from "react-feather"
import { useParams } from "react-router-dom"
import {
  scrollToPDM,
  useDashboardMyPharmacyLink,
} from "../hooks/useDashboardMyPharmacyLink"
import {
  type Challenge,
  HistoryLayout,
  HistoryLine,
  LoyaltyCategoryDisplay,
  RatingCardGrid,
  RatingCardLabel,
  RatingCardLayout,
  RatingCardQuantity,
  RatingCardValue,
  TrackingRow,
  useLoyaltyCategoryApi,
} from "./commons"
import { SubCategoryBonus } from "./commons/SubCategoryBonus"

const category = "CATEGORICAL"

export const CategoricalCategory = () => {
  const { year: yearStr = `${new Date().getFullYear()}`, adherentId } = useParams()
  const year = Number.parseInt(yearStr)
  const { loyaltyCategory } = useLoyaltyCategoryApi({
    categoryEnum: category,
    year,
    adherentId,
  })
  const dashboardMyPharmacyLink = useDashboardMyPharmacyLink(adherentId)

  return (
    <LoyaltyCategoryDisplay
      loyaltyCategory={loyaltyCategory}
      currentYear={year}
      adherentId={adherentId}
    >
      {!!loyaltyCategory && (
        <>
          {/* CARDS */}
          <Stack gap={3.5}>
            {/* OLD API (for backward compatibility) */}
            {loyaltyCategory.challenges?.length ? (
              <RatingCardGrid px={4}>
                {loyaltyCategory.challenges?.map((challenge, index) => (
                  <CategoricalCard key={index} challenge={challenge} />
                ))}
              </RatingCardGrid>
            ) : (
              <>
                {/* NEW API */}
                {loyaltyCategory.subCategories?.map((subCategory) =>
                  subCategory.hasBonus ? (
                    <Card
                      key={subCategory.id}
                      component={Box}
                      px={4}
                      py={3.5}
                      bgcolor="#f0f3f9!important"
                      border="0px!important"
                    >
                      <SubCategoryBonus
                        bonusLabel="Bonus challenge catégoriel"
                        subCategory={subCategory}
                        renderNonBonusCard={(challenge) => (
                          <CategoricalCard challenge={challenge} />
                        )}
                      />
                    </Card>
                  ) : (
                    <RatingCardGrid px={4} key={subCategory.id}>
                      {subCategory.challenges?.map((challenge, index) => (
                        <CategoricalCard key={index} challenge={challenge} />
                      ))}
                    </RatingCardGrid>
                  ),
                )}
              </>
            )}
          </Stack>

          {/* TRACKING */}
          <TrackingRow
            category={category}
            totalEarnPoints={loyaltyCategory.totalEarnPoints}
            totalExpectedPoints={loyaltyCategory.totalExpectedPoints}
            totalUnlocked={loyaltyCategory.unlockedChallenge}
            lastYearValue={loyaltyCategory.lastYearValue}
          />

          {/* HISTORY */}
          <HistoryLayout>
            {(loyaltyCategory.history || [])?.map((history) => (
              <HistoryLine
                key={history.historyDate}
                date={history.historyDate}
                title={history.label}
                gain={history.pointDiff}
                isBonus={!!history.descriptionItem?.bonus}
              />
            ))}
          </HistoryLayout>

          <Box mt={3} textAlign="center">
            <ButtonLink
              variant="text"
              to={dashboardMyPharmacyLink}
              onClick={scrollToPDM}
              endIcon={<ChevronRight />}
              sx={{ color: "grey.dark" }}
            >
              Détail des PDM catégorielles dans Mon Officine
            </ButtonLink>
          </Box>
        </>
      )}
    </LoyaltyCategoryDisplay>
  )
}

const CategoricalCard = ({
  challenge: {
    tag,
    quantity,
    numberField,
    formattedLabel: label,
    expectedPoint: value,
    unlocked: active,
    tooltip,
  },
}: { challenge: Challenge }) => {
  const { palette, typography } = useTheme()
  return (
    <RatingCardLayout active={active}>
      <RatingCardLabel>
        {/* QUANTITY */}
        {!!quantity && (
          <RatingCardQuantity active={active}>{quantity}</RatingCardQuantity>
        )}

        {/* LABEL */}
        <CardLabel>
          {!!tag && (
            <Tag color={active ? palette.loyalty.dark3 : palette.loyalty.dark2}>
              {tag}
            </Tag>
          )}
          <Box
            component="div"
            sx={{
              display: "inline",
              "& *": { display: "inline" },
              "& svg": { verticalAlign: "middle" },
              color: palette.common.darkBlue,
            }}
          >
            <Typography
              component="span"
              color="inherit"
              fontSize={typography.pxToRem(15)}
              fontWeight={600}
            >
              {numberField}&nbsp;
            </Typography>
            <Typography
              component="div"
              fontSize={typography.pxToRem(13)}
              sx={{
                "& p,*": {
                  color: `${palette.common.darkBlue}!important`,
                },
              }}
            >
              <MarkdownDisplay>{label}</MarkdownDisplay>
            </Typography>
            {!!tooltip && (
              <Tooltip title={tooltip}>
                <Info height={16} color={palette.grey.light} />
              </Tooltip>
            )}
          </Box>
        </CardLabel>
      </RatingCardLabel>
      <RatingCardValue active={active} value={value} />
    </RatingCardLayout>
  )
}
const CardLabel = styled(Stack)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  "& p": {
    margin: 0,
    lineHeight: theme.typography.pxToRem(17),
  },
}))

const Tag = styled(Typography)(({ theme: { typography } }) => ({
  textTransform: "uppercase",
  fontSize: typography.pxToRem(14),
  fontWeight: 600,
}))
