import {
  Box,
  type BoxProps,
  Divider,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { PaperContainerSmallTitle, formatNumber } from "@pharmupp/p3-front-commons"
import { Fragment, type ReactNode } from "react"
import { Check } from "react-feather"
import {
  CardLabel,
  RatingCardGrid,
  RatingCardLabel,
  RatingCardLayout,
  RatingCardQuantity,
} from "./RatingCard"
import type { Challenge, SelectedSubCategory } from "./useLoyaltyCategoryApi"

export const SubCategoryBonus = ({
  bonusLabel,
  subCategory,
  renderNonBonusCard,
}: {
  bonusLabel?: string
  subCategory: SelectedSubCategory
  renderNonBonusCard: (challenge: Challenge) => ReactNode
}) => {
  const { palette } = useTheme()
  return (
    <RatingCardGrid>
      {/* NON BONUSES */}
      <Stack spacing={2}>
        {subCategory.nonBonusChallenges.map((challenge, i) => (
          <Fragment key={`${challenge.formattedLabel}_${i}`}>
            {renderNonBonusCard(challenge)}
          </Fragment>
        ))}
      </Stack>

      {/* BONUSES */}
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        justifyContent="center"
        position="relative"
        pl={4}
        width="90%"
      >
        {/* BRACKET DIVIDER */}
        <BracketRight />

        {/* TITLE */}
        <Stack direction="row" alignItems="center" spacing={1}>
          <TripleStar />
          <PaperContainerSmallTitle sx={{ color: palette.loyalty.main }}>
            {bonusLabel || "Bonus"}
          </PaperContainerSmallTitle>
        </Stack>

        {/* CARDS */}
        <Stack spacing={4}>
          {subCategory.bonusChallenges.map((challenge) => (
            <RatingCardLayout
              key={challenge.formattedLabel}
              active={challenge.unlocked}
              sx={{
                overflow: "visible",
                position: "relative",
                pr: 12,
              }}
            >
              <RatingCardLabel>
                <RatingCardQuantity active={challenge.unlocked}>
                  {challenge.numberField}
                </RatingCardQuantity>
                <CardLabel label={challenge.formattedLabel} />
              </RatingCardLabel>
              <BadgeBonusPoints
                unlocked={challenge.unlocked}
                bonus={
                  challenge.unlocked && challenge.totalPointsUnlocked
                    ? challenge.totalPointsUnlocked
                    : challenge.expectedPoint
                }
                style={{ position: "absolute", right: "-22px" }}
              />
            </RatingCardLayout>
          ))}
        </Stack>
      </Box>
    </RatingCardGrid>
  )
}

const BracketRight = styled("span")(({ theme: { palette, spacing } }) => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: "-16px",
  border: `1px solid ${palette.loyalty.main}`,
  borderLeft: "0px",
  display: "block",
  width: spacing(2),
  height: "100%",
  "&:after": {
    content: "'▶'",
    display: "block",
    color: palette.loyalty.main,
    width: "5px",
    fontSize: "10px",
    position: "absolute",
    top: "20%",
    right: "-4px",
  },
}))

const TripleStar = () => (
  <Box display="flex" alignItems="center">
    {Array.from({ length: 3 }).map((_, index) => (
      <Box
        key={index}
        component="img"
        src="/icons/bonus-star.svg"
        alt=""
        ml="-12px"
      />
    ))}
  </Box>
)

const BadgeBonusPoints = ({
  unlocked,
  bonus,
  ...props
}: BoxProps & { unlocked: boolean; bonus: number }) => {
  const { palette, typography } = useTheme()
  return (
    <Box width="96px" height="96px" {...props}>
      <Box
        width="96px"
        height="96px"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/* SVG BACKGROUND */}
        <Box
          component="img"
          src={
            unlocked
              ? "/illustration/bonus-tag-unlocked.svg"
              : "/illustration/bonus-tag.svg"
          }
          alt=""
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
        />

        {/* TEXT */}
        <Box
          zIndex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={0.25}
          color={unlocked ? "white" : palette.loyalty.dark2}
        >
          <Box position="relative">
            {unlocked && (
              <Check
                size={22}
                style={{
                  position: "absolute",
                  top: -18,
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              />
            )}
            <Typography
              fontSize={typography.pxToRem(15)}
              fontWeight={600}
              color="inherit"
            >
              {`${formatNumber(bonus)} pts`}
            </Typography>
          </Box>
          {!unlocked && <Divider flexItem sx={{ color: palette.loyalty.dark2 }} />}
        </Box>
      </Box>
    </Box>
  )
}
