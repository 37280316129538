import {
  Box,
  Card,
  CardActionArea,
  IconButton as MuiIconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import {
  formatDateWithSlashes,
  useDialog,
  useQueryClient,
  useSidePanel,
} from "@pharmupp/p3-front-commons"
import { Fragment, type MouseEventHandler, useEffect, useRef } from "react"
import { ChevronRight, Paperclip, Plus, Trash2 } from "react-feather"
import {
  type MeetingNote,
  getMeetingNoteApiEndpoint,
  getMeetingNoteQueryKey,
} from "../api/useMeetingNoteApi"
import { MeetingNoteForm, MeetingNoteTitle } from "./MeetingNoteForm"
import { MeetingNoteCategoryEnum } from "./MeetingNoteForm/MeetingNoteReferential"

const AGENDA_BLUE = "#2989ff"
const LIGHT_GREY = "#a4a6b1"

// Lines
export const MeetingNoteLine = ({ meetingNote, labId }: RawLayoutProps) => (
  <RowLayout isShort meetingNote={meetingNote} labId={labId} />
)
export const CreateMeetingNoteLine = ({ labId }: RawLayoutProps) => (
  <RowLayout isShort labId={labId} />
)

// Cards
export const MeetingNoteCard = ({ meetingNote, labId }: RawLayoutProps) => (
  <LineCard meetingNote={meetingNote} labId={labId} />
)
export const CreateMeetingNoteCard = ({ labId }: RawLayoutProps) => (
  <LineCard labId={labId} />
)

interface RawLayoutProps {
  labId: string
  isShort?: boolean
  meetingNote?: MeetingNote
}

const CHEVRON_CLASS = "meetingNote-end-chevron"
const RowLayout = ({ isShort = false, meetingNote, labId }: RawLayoutProps) => {
  const { palette } = useTheme()
  const categoryLabel =
    meetingNote?.category &&
    MeetingNoteCategoryEnum[meetingNote.category].formattedLabel

  const { openPanel, closePanel } = useSidePanel()
  const formRef = useRef<{
    resetForm: () => void
  } | null>(null)

  useEffect(() => {
    return () => {
      formRef.current = null
      closePanel()
    }
  }, [closePanel])

  return (
    <LineWrapper
      isShort
      onClick={() =>
        openPanel({
          children: (
            <MeetingNoteForm
              ref={formRef}
              labId={labId}
              meetingNoteId={meetingNote?.id}
            />
          ),
          onClose: () => formRef?.current?.resetForm(),
          title: <MeetingNoteTitle />,
        })
      }
    >
      <Stack
        display="grid"
        gridTemplateColumns="auto 1fr"
        alignItems="center"
        gap={1.5}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {/* DATE */}
        {meetingNote ? <DateBox date={meetingNote.meetingDate} /> : <CreateBox />}

        <Stack
          direction={isShort ? "column" : "row"}
          alignItems="flex-start"
          gap={isShort ? 1 : 0.5}
          overflow="hidden"
        >
          {/* CATEGORY */}
          {!!categoryLabel && (
            <Title noWrap>
              [{categoryLabel}] {meetingNote?.title && !isShort ? "-" : ""}
            </Title>
          )}
          {/* TITLE */}
          {!meetingNote?.id ? (
            <Title noWrap bold>
              Nouveau rendez-vous
            </Title>
          ) : !meetingNote?.category && !meetingNote?.title ? (
            <CategoryPlaceHolder
              isShort={isShort}
              createdDate={meetingNote.createdDate}
            />
          ) : (
            <Title noWrap bold flex={1}>
              {meetingNote.title}
            </Title>
          )}
        </Stack>
      </Stack>

      {/* END */}
      {!!meetingNote &&
        (!isShort ? (
          <ReportLink meetingNote={meetingNote} labId={labId} />
        ) : (
          <ChevronRight
            className={CHEVRON_CLASS}
            size="20px"
            color={palette.primary.dark4}
          />
        ))}
    </LineWrapper>
  )
}

const CategoryPlaceHolder = ({
  isShort,
  createdDate,
}: { isShort: boolean; createdDate: string }) => {
  const mainLabel = "Rendez-vous sans titre"
  const dateLabel = `créé le ${formatDateWithSlashes(createdDate)}`
  return isShort ? (
    <Fragment>
      <Typography noWrap fontWeight={400} width="100%" color={LIGHT_GREY}>
        {mainLabel}
        {"\n"}
      </Typography>
      <Typography noWrap fontWeight={400} width="100%" color={LIGHT_GREY}>
        {dateLabel}
      </Typography>
    </Fragment>
  ) : (
    <Typography noWrap fontWeight={400} color={LIGHT_GREY}>
      {mainLabel} {dateLabel}
    </Typography>
  )
}

const LineWrapper = styled(Box, {
  shouldForwardProp: (prop) => !(prop === "isShort"),
})<{ isShort: boolean }>(({ theme: { transitions, spacing }, isShort }) => ({
  display: "flex",
  gap: spacing(isShort ? 2 : 5),
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  transition: transitions.create("opacity"),
  [`.${CHEVRON_CLASS}`]: {
    transition: transitions.create("transform"),
    transform: "translateX(0)",
  },
  "&:hover": {
    opacity: 0.8,
    [`.${CHEVRON_CLASS}`]: {
      transform: "translateX(5px)",
    },
  },
}))

const Title = styled(Typography, {
  shouldForwardProp: (prop) => !(prop === "isShort"),
})<{ bold?: boolean }>(({ theme: { palette, typography }, bold }) => ({
  fontSize: typography.pxToRem(16),
  lineHeight: typography.pxToRem(18),
  color: palette.common.darkBlue,
  maxWidth: "100%",
  fontWeight: bold ? 600 : 400,
}))

const LineCard = ({ meetingNote, labId }: RawLayoutProps) => (
  <Card sx={{ borderStyle: meetingNote ? "solid" : "dashed" }}>
    <CardActionArea component={Box} sx={{ p: 1 }}>
      <RowLayout meetingNote={meetingNote} labId={labId} />
    </CardActionArea>
  </Card>
)

const IconButton = styled(MuiIconButton)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.error.main,
  },
}))

const ReportLink = ({
  meetingNote,
  labId,
}: {
  meetingNote: MeetingNote
  labId: string
}) => {
  const {
    palette: { grey },
    typography,
  } = useTheme()
  const dialog = useDialog()
  const queryClient = useQueryClient()

  const { id, actions, content, participants, title, meetingDate, createdDate } =
    meetingNote || {}
  const hasReport = !!actions || !!content || !!participants
  function refreshMeetingNote() {
    queryClient.invalidateQueries({
      queryKey: [...getMeetingNoteQueryKey(labId), "resourceList"],
    })
  }
  const SubtitleDate = meetingDate ? (
    <Box component="span" textTransform="uppercase">
      {getDay(meetingDate)} {getMonth(meetingDate)}
    </Box>
  ) : null
  const SubtitleText = title ? <Box component="span">{title}</Box> : null
  const SubtitleDefault = (
    <Box component="span">
      Rendez-vous sans titre créé le {formatDateWithSlashes(createdDate)}
    </Box>
  )
  const MessageText = (
    <Box whiteSpace="break-spaces" textAlign="center">
      Vous êtes sur le point de supprimer ce rendez-vous,{"\n"}
      {hasReport && (
        <Typography
          component="span"
          color="primary"
          fontWeight="600"
          sx={{ textDecoration: "underline" }}
        >
          ainsi que le compte rendu associé,{"\n"}
        </Typography>
      )}
      voulez-vous vraiment continuer ?
    </Box>
  )

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()

    if (!id) return

    dialog.delete({
      subtitle: SubtitleDate ? (
        <Fragment>
          {SubtitleDate} - {SubtitleText ?? SubtitleDefault}
        </Fragment>
      ) : (
        (SubtitleText ?? SubtitleDefault)
      ),
      message: MessageText,
      endpoint: getMeetingNoteApiEndpoint(labId) + meetingNote.id,
      onSuccess: () => refreshMeetingNote(),
      onError: () =>
        dialog.info({
          title: "Suppression",
          message: "Une erreur est survenue",
        }),
    })
  }

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {/* REPORT DISPLAY */}
      {/* Remplacer hasReport => Intervenants, Resumé, plan d'action */}
      {!hasReport ? (
        <Stack direction="row" alignItems="center" gap={1}>
          <Plus color={grey[400]} />
          <Typography
            sx={{
              whiteSpace: "nowrap",
              textDecoration: "underline",
              color: grey[400],
            }}
          >
            Ajouter un compte rendu
          </Typography>
        </Stack>
      ) : (
        <MuiLink display="flex" alignItems="center" noWrap gap={1} color="#81adf8">
          <Paperclip />
          <Typography whiteSpace="nowrap">Compte rendu</Typography>
        </MuiLink>
      )}

      {/* DELETE BTN */}
      <Box
        minHeight={56}
        borderLeft="1px solid #eaedef"
        display="flex"
        alignItems="center"
        px={1}
      >
        <Tooltip title="Supprimer" placement="top">
          <IconButton onClick={handleDelete} sx={{ color: "#8da2c6" }}>
            <Trash2 size={typography.pxToRem(16)} />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  )
}

export const Day = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(16),
  lineHeight: typography.pxToRem(18),
  fontWeight: 600,
  color: AGENDA_BLUE,
}))
export const Month = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(12),
  lineHeight: typography.pxToRem(14),
  fontWeight: 600,
  color: LIGHT_GREY,
  textTransform: "uppercase",
}))
export const Year = styled(Typography)(({ theme: { typography } }) => ({
  fontSize: typography.pxToRem(10),
  lineHeight: typography.pxToRem(12),
  fontWeight: 600,
  color: LIGHT_GREY,
  textTransform: "uppercase",
}))

const DateBox = ({ date }: { date?: string | null }) => (
  <SquareBox bgcolor="#f6f6fb">
    {!date ? (
      <>
        <Day>?</Day>
        <Month>DATE</Month>
      </>
    ) : (
      <>
        <Day>{getDay(date)}</Day>
        <Month>{getMonth(date)}</Month>
        <Year>{getYear(date)}</Year>
      </>
    )}
  </SquareBox>
)

const CreateBox = () => (
  <SquareBox border="1px solid rgb(237, 240, 247)">
    <Plus color={AGENDA_BLUE} size="20px" />
  </SquareBox>
)

const SquareBox = styled(Box)({
  borderRadius: "2px",
  aspectRatio: 1,
  minHeight: "56px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
})

const getDay = (date: string) =>
  new Date(date).toLocaleDateString("fr-FR", { day: "2-digit" })

const getMonth = (date: string) =>
  new Date(date).toLocaleDateString("fr-FR", { month: "short" })

const getYear = (date: string) =>
  new Date(date).toLocaleDateString("fr-FR", { year: "numeric" })
