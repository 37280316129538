import {
  Box,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import type { PropsWithChildren } from "react"
import { Info } from "react-feather"
import {
  EvolutionStatChip,
  type EvolutionStatChipProps,
  formatPrice,
  noDecimalsFormatOptions,
} from "../../dataDisplay"

interface SalesCardProps {
  title: string
  infoTooltip?: string
  kpi?: number | null
  evol?: number | null
  cardFitContent?: boolean
  configEvol?: Omit<EvolutionStatChipProps, "evol">
  noValue?: string
}

const DEFAULT_VALUE = {
  textGrey: "ACHAT TOTAL",
  noValue: "-",
  noKpiContent: "-",
}

const COLOR = {
  border: "#e1e4e8",
  iconInfo: "#c9d0d6",
  textGrey: "#a9afb8",
}

export const SalesCard = (props: PropsWithChildren<SalesCardProps>) => {
  const {
    title,
    infoTooltip,
    kpi,
    evol,
    cardFitContent,
    configEvol,
    noValue = DEFAULT_VALUE.noValue,
    children,
  } = props
  // Allows you to test whether the property is in use
  // We can have evol with the value “undefined”
  // and sometimes we don't want to use evol and we also get “undefined”.
  const displayEvolution = Object.hasOwn(props, "evol")

  return (
    <Card
      sx={{
        borderColor: COLOR.border,
        width: cardFitContent ? "fit-content" : "100%",
      }}
    >
      <CardContent>
        <Title>
          {title} {infoTooltip && <InfoTooltip title={infoTooltip} />}
        </Title>
        <Stack px={2} pt={3} gap={1}>
          <TextGrey>{DEFAULT_VALUE.textGrey}</TextGrey>
          {kpi != null ? (
            <>
              <TextPrice>
                {formatPrice(kpi, noDecimalsFormatOptions) ||
                  DEFAULT_VALUE.noKpiContent}
              </TextPrice>
              {displayEvolution && (
                <Box>
                  <EvolutionStatChip evol={evol} {...configEvol} />
                </Box>
              )}
            </>
          ) : (
            <Typography color="grey.light">{noValue}</Typography>
          )}
          {children}
        </Stack>
      </CardContent>
    </Card>
  )
}

export const InfoTooltip = ({ title }: { title: string }) => {
  const { typography } = useTheme()
  return (
    <Tooltip title={title}>
      <Info
        color={COLOR.iconInfo}
        size={typography.pxToRem(20)}
        style={{ marginRight: -10 }}
      />
    </Tooltip>
  )
}

const Title = styled("span")(({ theme: { spacing, typography, palette } }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: spacing(2),
  flex: 1,
  padding: `${spacing(0.5)} ${spacing(2)}`,
  borderRadius: spacing(0.5),
  fontSize: typography.pxToRem(16),
  fontWeight: "500",
  color: palette.common.white,
  textTransform: "uppercase",
  backgroundColor: palette.primary.dark1,
  whiteSpace: "nowrap",
}))

const TextGrey = styled("span")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: "500",
  color: COLOR.textGrey,
}))
const TextPrice = styled("span")(({ theme }) => ({
  fontSize: theme.typography.pxToRem(21),
  fontWeight: "600",
  color: theme.palette.common.darkBlue,
  whiteSpace: "nowrap",
  lineHeight: theme.typography.pxToRem(18),
}))
