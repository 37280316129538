import {
  type ReferentialEnum,
  pickFromObject,
  referentialEnumToReferentialCodeList,
} from "@pharmupp/p3-front-commons"

export type NegotiationStatusName = keyof typeof negotiationStatusEnum
export const negotiationStatusEnum = {
  AGREEMENT_TERMINATED: { label: "Rupture d’accord" },
  PROSPECT: { label: "Prospect" },
  IN_PROGRESS: { label: "En cours" },
  UNSTABLE_TERMS: { label: "Tension avec le labo" },
  FINALIZED: { label: "Finalisé" },
  NOT_APPLICABLE: { label: "Néant" },
} as const satisfies ReferentialEnum
export const negotiationStatusReferential =
  referentialEnumToReferentialCodeList(negotiationStatusEnum)

export type PartnershipName = keyof typeof partnershipEnum
export const partnershipEnum = {
  NONE: { label: "Non partenaire" },
  CLASSIC: { label: "Classique" },
  PREMIUM: { label: "Premium" },
  PRIVILEGED: { label: "Privilégié" },
} as const satisfies ReferentialEnum
export const partnershipReferential =
  referentialEnumToReferentialCodeList(partnershipEnum)

export type MarketShareName = keyof typeof marketShareEnum
export const marketShareEnum = {
  WOUND_DRESSINGS: { label: "Pansements techniques" },
  DIGESTIVE_SYSTEM: { label: "Appareil digestif et métabolisme" },
  RESPIRATORY_SYSTEM: { label: "Système respiratoire" },
  CENTRAL_NERVOUS_SYSTEM: { label: "Système nerveux central" },
  GENERICS: { label: "Génériques" },
  VACCINATION: { label: "Vaccination Grippe" },
  BIOSIMILARS: { label: "Biosimilaires substituables" },
  INCONTINENCE: { label: "Incontinence" },
  ORTHOPEDICS: { label: "Orthopédie" },
  COMPRESSION: { label: "Compression veineuse" },
  NUTRITION: { label: "Nutrition" },
  WHITE_PRODUCT_LINE: { label: "Gamme blanche" },
  SKIN_CARE: { label: "Soins corps - Apaisants, brûlures, cicatrices" },
  DIETARY_INTESTINAL_COMFORT: {
    label: "Compléments alimentaires – Confort intestinal",
  },
  SOLAR_PROTECTION: { label: "Solaires" },
  ANTISEPTICS_DISINFECTANTS: {
    label: "Antiseptiques et/ou désinfectants",
  },
  HYGIENE_SOAPS: {
    label: "Hygiène - Savons et autres produits nettoyants",
  },
  DIABETES_INSULIN: {
    label: "Diabète – aiguilles pr stylos injecteurs d'insuline",
  },
  AROMATHERAPY: { label: "Aromathérapie" },
  DIETARY_ENERGY_ADULT: {
    label: "Compléments alimentaires – Vitalité – Adulte",
  },
  DIETARY_SLEEP_RELAXATION: {
    label: "Compléments alimentaires – Sommeil et/ou Détente",
  },
  DIABETES_GLYCEMIA: {
    label: "Diabète – bandelettes pr lecteurs de glycémie",
  },
  DIETARY_RESPIRATORY_SUPPORT: {
    label: "Compléments alimentaires – Voies respiratoires, gorge, ORL",
  },
  SMOKING_CESSATION: { label: "Médicaments Antitabac" },
  INFANT_NUTRITION: { label: "Diététique infantile" },
  OPHTHALMIC_HYGIENE: { label: "Hygiène Ophtalmique dont DM" },
  DERMATOLOGY_MEDICATIONS: { label: "Médicaments dermatologiques" },
} as const satisfies ReferentialEnum
export const categoriesReferential =
  referentialEnumToReferentialCodeList(marketShareEnum)

export type StatusesName = keyof typeof statusesEnum
export const statusesEnum = {
  ACTIVE: { label: "Actif", color: "success.main" },
  DEACTIVATED: { label: "Inactif", color: "error.main" },
} as const satisfies ReferentialEnum<{ color: string }>
export const statusesReferential = referentialEnumToReferentialCodeList(statusesEnum)

export type CatalogName = keyof typeof catalogLabelEnum
export const catalogLabelEnum = {
  fdl: { label: "FDL" },
  sagitta: { label: "Ma Boutique" },
  coalia: { label: "Coalia" },
  sdav: { label: "SDAV" },
} as const satisfies ReferentialEnum
export type CatalogType = Record<CatalogName, boolean>

export type MarketShareCategoriesChallengeName =
  keyof typeof marketShareCategoriesChallengeEnum
export const marketShareCategoriesChallengeEnum = pickFromObject(marketShareEnum, [
  "ORTHOPEDICS",
  "COMPRESSION",
  "NUTRITION",
  "INCONTINENCE",
  "GENERICS",
  "BIOSIMILARS",
  "WHITE_PRODUCT_LINE",
  "VACCINATION",
])

export const marketShareCategoriesChallengeReferential =
  referentialEnumToReferentialCodeList(marketShareCategoriesChallengeEnum)
