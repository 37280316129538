import {
  type Direction,
  api,
  useMutation,
  useQuery,
  useQueryClient,
  useResourceListApi,
  useToaster,
} from "@pharmupp/p3-front-commons"
import type { MeetingNoteFormValues } from "../MeetingNote/MeetingNoteForm/ MeetingNote.schema"
import type { MeetingNoteCategory } from "../MeetingNote/MeetingNoteForm/MeetingNoteReferential"

export const getMeetingNoteApiEndpoint = (labId: string | number) =>
  `/api/laboratories/adherent/${labId}/meeting-notes/`

export const getMeetingNoteQueryKey = (labId: string | number) => [
  "laboratories",
  "adherent",
  labId,
  "meeting-notes",
]

// GET / CREATE / UPDATE => Meeting note
export const useMeetingNoteApi = ({
  labId,
  meetingNoteId,
}: {
  labId: string
  meetingNoteId?: number
}) => {
  const isUpdate = !!meetingNoteId
  const queryClient = useQueryClient()
  const toaster = useToaster()

  // GET
  const { data, isLoading } = useQuery({
    queryKey: [...getMeetingNoteQueryKey(labId), meetingNoteId ?? ""],
    queryFn: meetingNoteId
      ? () => {
          return api.get<MeetingNote>(
            `${getMeetingNoteApiEndpoint(labId)}${meetingNoteId}`,
          )
        }
      : undefined,
    enabled: !!meetingNoteId,
  })

  // CREATE / UPDATE
  const {
    mutateAsync: sendValues,
    data: updatedNote,
    reset,
    status,
  } = useMutation({
    mutationFn: (formValues: MeetingNoteFormValues) => {
      return api<MeetingNote>(
        `${getMeetingNoteApiEndpoint(labId)}${meetingNoteId || ""}`,
        {
          method: isUpdate ? "PUT" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formValues),
        },
      )
    },
    onSuccess(data) {
      queryClient.setQueryData([...getMeetingNoteQueryKey(labId), data.id], data)
      queryClient.invalidateQueries({
        queryKey: [...getMeetingNoteQueryKey(labId), "resourceList"],
      })
    },
    onError() {
      toaster.error("Une erreur est survenue.")
    },
  })

  const meetingNote = (
    meetingNoteId ? data : (updatedNote ?? defaultValues)
  ) as MeetingNoteFormValues

  const noteId = updatedNote?.id ?? data?.id

  return {
    meetingNote,
    sendValues,
    updatedId: noteId,
    reset,
    isLoading: isLoading || status === "pending",
  }
}

// GET => Meeting note list
export const useMeetingNoteApiList = ({
  labId,
  sortDateDirection = "desc",
  size = -1,
}: { labId: string; sortDateDirection?: Direction; size?: number }) => {
  const { list: meetingNotes, contentLoading } = useResourceListApi<MeetingNote>({
    queryKey: getMeetingNoteQueryKey(labId),
    resourceEndpoint: `${getMeetingNoteApiEndpoint(labId)}`,
    defaultColumnSort: "meetingDate",
    defaultColumnDirection: sortDateDirection,
    rowsPerPage: size,
  })

  return { meetingNotes, contentLoading }
}

export interface MeetingNote {
  meetingDate?: string | null // format yyyyy-MM-dd
  title?: string | null
  category?: MeetingNoteCategory
  content?: string | null
  actions?: string | null
  participants?: string | null
  id: number
  laboratoryId?: number | null
  createdDate: string
  updatedDate?: string | null
}

const defaultValues: MeetingNoteFormValues = {
  meetingDate: null,
  title: null,
  category: null,
  content: null,
  actions: null,
  participants: null,
}
