import {
  Box,
  Link as MuiLink,
  Stack,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { Link, SalesCard, useSsoService } from "@pharmupp/p3-front-commons"
import { ExternalLink } from "react-feather"
import type { ServiceTurnoverStats } from "../../../kpi.models"
import { LineTitle } from "./LineBlock"

const MoreDetailFDL = () => {
  const theme = useTheme()
  const sso = useSsoService()
  const ids = sso.getIds()

  return (
    <Box
      display="flex"
      component="form"
      target="_blank"
      method="post"
      action="https://www.cerp-rrm.com/niveau2/statupp/sommaire.asp"
    >
      <input type="hidden" name="client" value={ids?.cerp} />
      <input type="hidden" name="archive" value="0" />
      <MuiLink underline="hover" component="button" type="submit" width="100%">
        <Stack
          component={Typography}
          fontSize={theme.typography.pxToRem(14)}
          direction="row"
          spacing={0.5}
          alignItems="center"
          color="primary.light"
        >
          <span>Plus de détails</span>
          <ExternalLink size={theme.typography.pxToRem(14)} />
        </Stack>
      </MuiLink>
    </Box>
  )
}

const MoreDetailCOALIA = () => (
  <Link display="flex" underline="hover" to="./coalia">
    <Typography
      color="primary.light"
      fontSize={({ typography }) => typography.pxToRem(14)}
    >
      Plus de détails
    </Typography>
  </Link>
)

const commonTooltip = `Total achats du dernier mois échu : ${new Date(
  new Date().setDate(0),
).toLocaleDateString("fr-FR", { month: "long", year: "numeric" })}`

export const serviceTurnoverTitle = (year: number) => {
  return year === new Date().getFullYear()
    ? "Suivi par canal (HT)"
    : `Suivi ${year} par canal (HT)`
}

export const ServiceTurnover = ({
  serviceTurnover,
}: {
  serviceTurnover: ServiceTurnoverStats
}) => (
  <Stack direction="column" spacing={3}>
    <LineTitle>{serviceTurnoverTitle(serviceTurnover.year)}</LineTitle>
    <TurnoverContainer>
      <SalesCard
        title="coalia"
        kpi={serviceTurnover.coalia}
        infoTooltip={commonTooltip}
      >
        <MoreDetailCOALIA />
      </SalesCard>
      <SalesCard title="sdav" kpi={serviceTurnover.sdav} infoTooltip="MAJ J-1" />
      <SalesCard title="fdl" kpi={serviceTurnover.fdl} infoTooltip={commonTooltip}>
        <MoreDetailFDL />
      </SalesCard>
      <SalesCard
        title="MA BOUTIQUE"
        kpi={serviceTurnover.sagitta}
        infoTooltip={commonTooltip}
      />
      <SalesCard
        title="pharmat"
        kpi={serviceTurnover.pharmat}
        infoTooltip={commonTooltip}
      />
    </TurnoverContainer>
  </Stack>
)

const TurnoverContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  columnGap: theme.spacing(3),
  rowGap: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}))
