import { Box, CircularProgress, Stack, Typography } from "@mui/material"
import {
  ChallengeTrackingRow,
  GoalTrackingContainer,
  GoalTrackingPlaceholder,
} from "@pharmupp/p3-front-commons"
import { getStatusProp } from "../commons/trackingHelpers"
import type { ApiLoyaltyCategoryEnum } from "../types"
import { useLoyaltyCategoryListApi } from "./useLoyaltyCategoryListApi"

export interface LoyaltyCategoryListProps {
  categoryLink: (category: ApiLoyaltyCategoryEnum) => string
  year: number
  adherentId?: string
}
export const LoyaltyCategoryList = ({
  categoryLink,
  year,
  adherentId,
}: LoyaltyCategoryListProps) => {
  const { challengeCategories, loading } = useLoyaltyCategoryListApi({
    year,
    adherentId,
  })

  return (
    <Box width="100%" minHeight="300px">
      {loading || !challengeCategories ? (
        <CircularProgress sx={{ display: "block", m: "150px auto" }} />
      ) : (
        <GoalTrackingContainer>
          {challengeCategories.length > 0 ? (
            challengeCategories.map((category) => (
              <ChallengeTrackingRow
                key={category.name}
                link={categoryLink(category.categoryEnum)}
                title={category.name.toUpperCase()}
                status={getStatusProp({
                  quantity: category.unlockedChallenge,
                  category: category.categoryEnum,
                })}
                currentValue={category.earnPoint}
                lastYearValue={category.lastYearEarnedPoints}
                goal={category.expectedPoint}
              />
            ))
          ) : (
            // EMPTY CASE
            <Stack gap={0.5}>
              <Typography ml={4} mb={2} variant="18px" color="loyalty.main">
                Nouveaux Challenges en préparation ...
              </Typography>
              <GoalTrackingPlaceholder title="Pas de challenge actif pour le moment" />
            </Stack>
          )}
        </GoalTrackingContainer>
      )}
    </Box>
  )
}
