import { Box, IconButton, darken, lighten, styled } from "@mui/material"
import { useMemo } from "react"
import { ChevronLeft, ChevronRight } from "react-feather"
import type { Offer, OfferSource, TagObject } from "../../../types"
import { LastCard, OfferCard } from "./Card"
import { OfferName } from "./OfferName"
import { useCardSlider } from "./useCardSlider"

const isLastCard = (
  promo: Offer | { lastCard: boolean },
): promo is { lastCard: boolean } => "lastCard" in promo

export const OffersSlider = ({
  offers: rawOffers,
  source,
  allOffersLink,
  onOfferClick,
}: {
  offers: Offer[]
  source: OfferSource
  allOffersLink: string
  onOfferClick: (offer: Offer) => void
}) => {
  const offers = useMemo(() => [...rawOffers, { lastCard: true }], [rawOffers])

  // Scroll
  const { refHandler, scrollLeft, scrollRight, scrollPosition } = useCardSlider({
    cardBaseWidthInPx: "150px",
    cardSpacing: 3,
  })

  return (
    <MyGrid>
      <OfferName offer={source} href={allOffersLink} />
      <Box borderRight={1} borderColor="grey.extraLight" position="relative">
        {scrollPosition !== "start" && (
          <SliderButton
            onClick={scrollLeft}
            sx={{
              position: "absolute",
              right: "-20px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <ChevronLeft />
          </SliderButton>
        )}
      </Box>
      <Box ref={refHandler}>
        {offers.map((offer, index) =>
          isLastCard(offer) ? (
            <LastCard key={index} offer={source} link={allOffersLink} />
          ) : (
            <OfferCard
              key={index}
              onClick={() => onOfferClick(offer)}
              timeType="IN_PROGRESS"
              startDate={offer.startDateFrenchFormat}
              endDate={offer.endDateFrenchFormat}
              title={offer.title}
              logoUrl={offer.labLogoUrl}
              tag={offer.tags[1] as TagObject}
              isCatalog={offer.isCatalog}
            />
          ),
        )}
      </Box>
      <Box borderLeft={1} borderColor="grey.extraLight" position="relative">
        {scrollPosition !== "end" && (
          <SliderButton
            onClick={scrollRight}
            sx={{
              position: "absolute",
              left: "-20px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <ChevronRight />
          </SliderButton>
        )}
      </Box>
    </MyGrid>
  )
}

const MyGrid = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "100px 1fr 100fr 1fr",
  gridColumnGap: theme.spacing(3),
}))

const SliderButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.grey.extraLight,
  ":hover": {
    backgroundColor: darken(theme.palette.grey.extraLight, 0.1),
  },
  ":disabled": {
    backgroundColor: lighten(theme.palette.grey.extraLight, 0.7),
  },
}))
