import {
  type ReferentialEnum,
  referentialEnumToReferentialCodeList,
} from "@pharmupp/p3-front-commons"

export type MeetingNoteCategory = keyof typeof MeetingNoteCategoryEnum
export const MeetingNoteCategoryEnum = {
  FINANCIAL: { label: "Points chiffres", formattedLabel: "Points chiffres" },
  NEGOTIATION: { label: "Négo", formattedLabel: "Négo" },
  OFFERS: { label: "Commandes / promos", formattedLabel: "Commandes / promos" },
  COOP: { label: "MEA / Coop", formattedLabel: "MEA / Coop" },
  MECHANDISING: { label: "Merchandising", formattedLabel: "Merchandising" },
  TRAINING: { label: "Formation", formattedLabel: "Formation" },
  CUSTOMER_SERVICE: { label: "SAV", formattedLabel: "SAV" },
} as const satisfies ReferentialEnum<{ formattedLabel: string }>

export const MeetingNoteCategoryRefential = referentialEnumToReferentialCodeList(
  MeetingNoteCategoryEnum,
)
