import { api, useSuspenseQuery } from "@pharmupp/p3-front-commons"
import { type Period, periodDates } from "./useLabMarketShareApi"

export const useSalesSummaryApi = ({
  labId,
  period,
}: { labId: string; period: Period }) => {
  const dateRange = periodDates[period]

  const { data: salesSummary, isLoading } = useSuspenseQuery({
    queryKey: ["laboratories", labId, "adherent", "sales", "summary", period],
    queryFn: () =>
      api.get<ApiSalesSummary>(
        `/api/laboratories/adherent/${labId}/sales/summary?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
      ),
  })

  return { salesSummary, isLoading }
}

export type ApiSalesSummary = {
  turnover?: KeyFigure
  units?: KeyFigure
  numReferences?: number
}

export interface KeyFigure {
  mainStat?: number | null
  evolution?: number | null
}
