import {
  Box,
  Divider,
  Stack,
  type StackProps,
  Typography,
  styled,
  useTheme,
} from "@mui/material"
import { formatNumber } from "@pharmupp/p3-front-commons"
import { Children, type ReactNode } from "react"
import { Check, MoreHorizontal, X } from "react-feather"

interface HistoryLayoutProps {
  title?: string
  children?: ReactNode[]
}

export const HistoryLayout = ({
  title = "Historique des points cumulés",
  children,
}: HistoryLayoutProps) => {
  if (!children || Children.count(children) === 0) return null

  return (
    <Box>
      <HistoryTitle mt={8} mb={1}>
        {title}
      </HistoryTitle>

      <HistoryBox divider={<Divider />}>{children}</HistoryBox>
    </Box>
  )
}

const HistoryTitle = styled(Typography)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(14),
  textAlign: "center",
  fontWeight: 500,
  color: palette.grey.light,
  textTransform: "uppercase",
}))

const HistoryBox = styled(Stack)(({ theme: { palette } }) => ({
  borderTop: `1px solid ${palette.grey.extraLight}`,
  borderBottom: `1px solid ${palette.grey.extraLight}`,
}))

export interface HistoryLineProps {
  date: string
  title: string
  gain: number
  isBonus: boolean
}
export const HistoryLine = ({ date, title, gain, isBonus }: HistoryLineProps) => (
  <HistoryLineBox gridTemplateColumns="33% 1fr auto">
    {/* DATE */}
    <HistoryLineDate date={date} />

    {/* TITLE */}
    <HistoryLineTitle title={title} gain={gain} isBonus={isBonus} />

    {/* BONUS */}
    <HistoryLineBonus gain={gain} />
  </HistoryLineBox>
)

export const HistoryLineBox = ({
  children,
  ...props
}: { children: ReactNode } & StackProps) => (
  <Stack display="grid" color="grey.dark" py={2} {...props}>
    {children}
  </Stack>
)

export function HistoryLineBonus({
  gain,
  isPending,
  nbItemsRelative,
  nbItemsRelativeGoal,
}: {
  gain: number
  isPending?: boolean
  nbItemsRelative?: number
  nbItemsRelativeGoal?: number
}) {
  const { typography, palette } = useTheme()

  // const
  return (
    <Typography
      fontSize={typography.pxToRem(14)}
      fontWeight="bold"
      color={
        isPending
          ? palette.common.orange
          : gain >= 0
            ? palette.loyalty.main
            : palette.error.main
      }
    >
      {isPending
        ? `En cours ${nbItemsRelative}/${nbItemsRelativeGoal}`
        : `${formatNumber(gain, { signDisplay: "always" })} pts`}
    </Typography>
  )
}

export function HistoryLineDate({ date }: { date: string }) {
  return (
    <Typography fontSize={(theme) => theme.typography.pxToRem(14)}>
      {new Date(date).toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })}
    </Typography>
  )
}

export function HistoryLineTitle({
  title,
  gain,
  isPending = false,
  isBonus = false,
}: {
  title: string
  gain: number
  isPending?: boolean
  isBonus?: boolean
}) {
  const { palette, typography } = useTheme()
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      spacing={1}
    >
      {isPending ? (
        <MoreHorizontal color={palette.common.orange} />
      ) : (
        <TitleIcon gain={gain} isBonus={isBonus} />
      )}
      <Typography fontSize={typography.pxToRem(14)} fontWeight={800}>
        {title}
      </Typography>
    </Stack>
  )
}

const TitleIcon = ({ gain, isBonus }: { gain: number; isBonus: boolean }) => {
  const { palette } = useTheme()
  if (gain >= 0) {
    return isBonus ? <Star /> : <Check color={palette.success.main} />
  }
  return <X color={palette.error.main} />
}
const Star = () => <img src={"/icons/bonus-star.svg"} alt="étoile bonus" />
